import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import { ExpressProcessStyles } from "../index.css";
import SignatureType from "../molecules/signatureType";
import Participants from "../molecules/participants";
import AddDocuments from "../molecules/addDocuments";

import WelcomeOptionsContent from "../molecules/welcomeOptionsContent";
import {
    asyncFileToBase64,
    getErrorContractCreation,
    to,
    typeUser,
    verifiVariable,
    verifyRol,
} from "../../../../utils/enums";
import PreviewDocument from "../molecules/previewDocument";
import { clearForm, createTramit, saveTramitExpressInfo } from "../../../../store/formExpressTramit";
import Spinner from "../../../spinners/Spinner";
import ErrorAlert from "../molecules/errorAlert";
import { Container, Typography } from "@mui/material";
//import ExpressProcesSteps from "../molecules/stepProcess";
import ModalContractUploadError from "../../../modalContractUploadError";
import UserManuals from "../../../userManuals";
//import AttachedCDV from "../../../attachedCDV";
import ModalContractCreatedAndContractSteps from "../../../modalContractCreatedAndContractSteps";
import ContractName from "../../../contractName";
import { theme } from "../../../../theme/theme";
import { saveParticipants } from "../../../../store/contract/frequentParticipants";
//import ModalContractCreated from "../../../modalContractCreated";

const ActionsButton = styled(Button)((props) => ({
    width: "47%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "1rem",
    padding: "9px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const HomePage = () => {
    const {
        formExpressTramit: {
            document,
            participants,
            signatureType,
            optionSignature,
            optionTypeTramit,
            status,
            message,
            detailContract,
            uploadProgress,
            firms,
            agrement,
            statusMergeDoc,
            tramitType,
            contractName,
            //attachedCDV
        },
        session: {
            login: { user, userInfo },
        },
    } = useSelector((state) => state.root);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const styles = ExpressProcessStyles({ document });
    const [showError, setShowError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [textError, setTextError] = useState([]);
    const [titleError, setTitleError] = useState("");
    const [sigCountTramit1, setSigCountTramit1] = useState(0);
    const [sigCountTramit2, setSigCountTramit2] = useState(0);
    const [typeSigValid, setTypeSigValid] = useState(true);
    const [openModalSteps, setOpenModalSteps] = useState(false);
    //const [openModalCreatedSucess, setOpenModalCreatedSucess] = useState(false);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);
    const [phrases, setPhrases] = useState([]);

    const ButtonInfo = styled(Button)((props) => ({
        width: "100%",
        fontStyle: "normal",
        boxShadow: "none",
        fontWeight: "bolder",
        textTransform: "none",
        fontSize: "1rem",
        padding: "9px 26px",
        border: "1px solid",
        borderRadius: "7px",
        lineHeight: 1.5,
        fontFamily: ['"PoppinsBold"'].join(","),
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    }));

    const tramitTypeName = tramitType?.['name']

    const verifyData = () => {
        const arrayData = [...participants];

        const verifyPay = arrayData.find((item) =>
            item.rol.includes("Pagador")
        );

        const verifySigner = arrayData.find((item) => item.rol.includes("Firmante"));

        const isFullPay = arrayData
            .map(signer => Number(signer.pay))
            .reduce((acc, sum) => acc + sum, 0);

        const usersAsigned = arrayData
            .filter(signer => signer.rol.includes('Firmante'));

        const firmsAsigned = Object.entries(firms)
            .map(ele => ele.map(ele => ele.rut))
            .map(ele => ele[1]);
        const isAllSignersAsigned = usersAsigned
            .every(ele => firmsAsigned.includes(ele.rut));

        const errors = []
        if (userInfo && userInfo["iProfileID"] === typeUser.ADMIN && !agrement) errors.push('Debes seleccionar un convenio');
        if (!tramitType) errors.push('Debes seleccionar el tipo de trámite a gestionar');
        if (!signatureType) errors.push('Debes agregar un tipo de firma');
        if (arrayData.length === 0) errors.push('Debes agregar un participante');
        if (!verifyPay) errors.push('Debes asignar al menos un pagador');
        if (!document) errors.push('Debes subir tu documento');
        if (isFullPay !== 100) errors.push('Debes asignar el 100% del pago');
        if (!verifySigner) errors.push('Debes agregar al menos un firmante');
        //if (Object.keys({ ...firms }).length === 0) errors.push('Debes asignar firmas');
        if (signatureType !== "SIGNATURE_ADVANCE" && !isAllSignersAsigned) errors.push('Debes asignar firmas');

        if (typeSigValid === false) errors.push('Tipo de firma no valido para ' + tramitType["name"]);

        if (sigCountTramit1 === 0 || sigCountTramit2 === 0) {

            if (sigCountTramit1 === 1) {
                if (usersAsigned.length > 1) errors.push('Solo esta permitido un firmante para este trámite');
            }

            if (sigCountTramit2 === 2) {
                if (usersAsigned.length < 2) errors.push('Solo estan permitidos 2 o mas firmantes para este trámite');
            }

        }

        /* if ((
            tramitTypeName === 'Contrato de arriendo' ||
            tramitTypeName === 'Promesa de compraventa' ||
            tramitTypeName === 'Mandato de administración'
        ) && !attachedCDV) {
            errors.push('Debes notificar si va adjunto el certificado de dominio');
        } */
        return errors
    };

    const asyncPdfForSignrequest = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if ([null, undefined, ""].includes(document)) return;

                const dataFirms = { ...firms };

                const pdfDoc = await PDFDocument.load(document);

                // Embed the Helvetica font
                const helveticaFont = await pdfDoc.embedFont(
                    StandardFonts.Helvetica
                );

                // Get the first page of the document
                const pages = pdfDoc.getPages();

                Object.values(dataFirms).forEach((item) => {
                    const signedPage = pages[item["page"] - 1];

                    // Draw a string of text diagonally across the first page
                    signedPage.drawText(
                        item["text"],
                        Object.assign(
                            { ...item },
                            {
                                font: helveticaFont,
                                color: rgb(1, 1, 1),
                                y: signedPage.getHeight() - item["y"],
                            }
                        )
                    );
                });

                const pdfBytes = await pdfDoc.save();

                const file = new File([pdfBytes], "document", {
                    type: "application/pdf",
                });

                resolve(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const saveData = async () => {
        const verifiedData = verifyData();
        setErrorsUpload(verifiedData);
        if (verifiedData.length !== 0) {
            setOpenModalErrors(true);
            return
        }
        try {
            setLoading(true);
            setTextLoading("Creando Trámite, por favor espere");

            const arrayData = [...participants];

            let iSignedCount = arrayData.filter((item) => item['rol'].includes('Firmante')).length

            const { token } = user;

            let iContractTypeFeeID = 0;

            const fileSigReqest = await asyncPdfForSignrequest();

            const fileBase64 = await asyncFileToBase64(fileSigReqest);

            if (iSignedCount >= 2) {
                iContractTypeFeeID = optionTypeTramit.find((element) => element["iSignCount"] === 2 && element['sModal'] === signatureType)["iContractTypeFeeID"];
            } else if (iSignedCount === 1) {
                iContractTypeFeeID = optionTypeTramit.find((element) => element["iSignCount"] === 1 && element['sModal'] === signatureType)["iContractTypeFeeID"];
            }

            const data = {
                sOwnerType: "NATURAL",
                sPaymentStatus: "PENDING",
                iPaymentService: 0,
                iContractTypeFeeID: iContractTypeFeeID,
                iSignedCount,
                callback: "",
                iAgreementId: !verifiVariable(agrement)
                    ? agrement["agreementId"]
                    : 0,
                iPriceId: 0,
                //attachedCDV,
                ...(signatureType === 'SIGNATURE_ADVANCE' && { provider: 'FEA' }),
                sContractName: contractName,
                signers: arrayData.map((item) => {
                    const firmante = {
                        order: item["signerOrder"],
                        rol: verifyRol(item["rol"]),
                        payment: "0.00",
                        type: "NATURAL",
                        portion: item["pay"],
                        full_name: item["fullName"],
                        email: item["email"],
                        rutId: !verifiVariable(item["rut"]) ? item["rut"] : item["passport"],
                        phone: Number(item["phone"].replace('+', '')),
                    };
                    return firmante;
                }),
                document: {
                    content_file: fileBase64.replace(
                        "data:application/pdf;base64,",
                        ""
                    ),
                },
            };

            dispatch(saveParticipants([...participants]));

            dispatch(createTramit(token, data));

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        } catch (error) {
            console.log({ error });
        }
    };

    const handleDataClick = () => {
        saveData();
    };

    useEffect(() => {
        if (tramitTypeName !== 'Contrato de arriendo' &&
            tramitTypeName !== 'Promesa de compraventa' &&
            tramitTypeName !== 'Mandato de administración') {
            dispatch(
                saveTramitExpressInfo({
                    indexe: "attachedCDV",
                    value: null,
                })
            )
        }
    }, [tramitTypeName, dispatch]);

    useEffect(() => {
        if (statusMergeDoc === "fail") {
            setTitleError("Documento dañado.");
            setTextError(
                ["Ha ocurrido un problema con el documento. Por favor, verifíquelo e inténtelo nuevamente."]
            );

            setShowError((e) => 1 + e);
        }
    }, [statusMergeDoc]);

    useEffect(() => {
        if (uploadProgress !== 0) {
            setTextLoading("Limpiando archivo " + uploadProgress + "%");
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (status === "success" && !verifiVariable(message)) {

            if (("contract" in message) && ("sContractID" in message["contract"])) {
                //setOpenModalCreatedSucess(true);
                navigate(to.CONTRACTS_CREATED_SUCCESS, {
                    state: {
                        sContractID: message["contract"]["sContractID"],
                        tramitType: 'express'
                    },
                });
                dispatch(clearForm());
            };

            if (!("contract" in message) || !("sContractID" in message["contract"])) {
                setShowError((e) => 1 + e);
                setTextError(
                    ["Podrías intentar nuevamente o comunícate con un administrador del sistema."]
                );
                setTitleError("Ha surgido un problema al crear el trámite.");
            };
            setLoading(false);
        } else if (status === "fail") {

            const errors = getErrorContractCreation(message, 'express');

            if (errors.length === 0) {
                setShowError((e) => 1 + e);
                setTextError(
                    ["Podrías intentar nuevamente o comunícate con un administrador del sistema."]
                );
                setTitleError("Ha surgido un problema al crear el trámite.");
            }

            if (errors.length > 0) {
                setShowError((e) => 1 + e);
                setTextError([errors]);
                setTitleError("Ha surgido un problema al crear el trámite.");
            }

            setLoading(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, message, detailContract]);

    useEffect(() => {
        let windowWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            if (window.innerWidth !== windowWidth) {

                if (!verifiVariable(document)) {

                    setShowError((e) => 1 + e);

                    setTextError(["Hemos detectado un cambio en el tamaño del navegador, por esta razón debe reasignar las firmas."]);

                    setTitleError("Se ha detectado un cambio en el tamaño del navegador.");

                }

                windowWidth = window.innerWidth;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSigCountTramit2(0);
        setSigCountTramit1(0);
        setTypeSigValid(true);
        // console.log("optionTypeTramit: ", [...optionTypeTramit].filter((item) => item["sModal"] === signatureType)); 

        if (!verifiVariable(signatureType) && !verifiVariable(optionTypeTramit) && optionTypeTramit.length > 0 && !verifiVariable(tramitType)) {

            const arrayData = [...optionTypeTramit].filter((item) => item["sModal"] === signatureType);

            if (arrayData.length === 0) setTypeSigValid(false);

            arrayData.forEach(element => {
                if (element["iSignCount"] === 2) setSigCountTramit2(2);
                if (element["iSignCount"] === 1) setSigCountTramit1(1);
            });

        }

    }, [signatureType, optionTypeTramit, tramitType, optionSignature]);

    return (
        <Container maxWidth={'xl'} sx={{ marginTop: '1rem' }}>
            {loading && (
                <Spinner
                    loading={loading}
                    type="bar"
                    text={textLoading}
                    opacity={true}
                />
            )}

            <Box className={styles.mainContent}>
                <ErrorAlert msn={textError} title={titleError} errors={showError} />

                <ModalContractUploadError
                    openModalErrors={openModalErrors}
                    setOpenModalErrors={setOpenModalErrors}
                    data={errorsUpload}
                    modalIcon={'warnIcon'}
                />

                <ModalContractCreatedAndContractSteps
                    openModal={openModalSteps}
                    setOpenModal={setOpenModalSteps}
                    tramitType={'express'}
                />

                <Grid container>
                    {isMobile && (
                        <Grid item xs={12} md={6} lg={7}>
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.document}`}
                            >
                                {!verifiVariable(document) && (
                                    <PreviewDocument onPhrases={(value) => setPhrases(value)} />
                                )}
                            </Item>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6} lg={5} sx={{ zIndex: 99 }}>
                        <Item className={`${styles.contenItem} ${styles.formItem}`}>
                            <UserManuals download={'https://drive.google.com/file/d/1A-lXOk75MuHMH35S1cvc8rRK3Nzxr12Q/view?usp=sharing'} />

                            <SignatureType />

                            <Participants />

                            {/* {
                            (
                                tramitTypeName === 'Contrato de arriendo' ||
                                tramitTypeName === 'Promesa de compraventa' ||
                                tramitTypeName === 'Mandato de administración'
                            ) && (
                                <AttachedCDV signatureType={'express'} />
                            )
                        } */}

                            <ContractName signatureType={'express'} />

                            <AddDocuments
                                onResetPhrases={() => setPhrases([])}
                                loadingModaldocs={(e) => setLoading(e)}
                                textLoadingModaldocs={(e) => setTextLoading(e)}
                            />

                            <Box className={styles.stepContainerActionsButtoms}>
                                <ActionsButton
                                    onClick={() => dispatch(clearForm())}
                                    variant="contained"
                                    color="primary"
                                >
                                    Limpiar
                                </ActionsButton>

                                <ActionsButton
                                    disabled={phrases.length !== 0}
                                    onClick={() => handleDataClick()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Finalizar
                                </ActionsButton>
                            </Box>

                            <Item
                                className={`${styles.contenItem} ${styles.formItem}`}
                            >
                                <Box
                                    className={`${styles.stepContainerActionsButtoms} ${styles.managProc}`}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    // className={styles.titleFV}
                                    >
                                        Conoce los pasos de validación y
                                        notificación que llegan al correo de
                                        los participantes
                                    </Typography>

                                    <ButtonInfo
                                        onClick={() =>
                                            setOpenModalSteps(true)
                                        }
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Ingresa aquí
                                    </ButtonInfo>
                                </Box>
                            </Item>
                        </Item>
                    </Grid>

                    {!isMobile && (
                        <Grid item xs={12} sm={6} lg={7}>
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.document}`}
                            >
                                {!verifiVariable(document) &&
                                    !isMobile ? (
                                    <PreviewDocument onPhrases={(value) => setPhrases(value)} />
                                ) : (
                                    <WelcomeOptionsContent />
                                )}
                            </Item>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default HomePage;