import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Avatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

import { getUserInfo } from "../storeSelector/storeSelector";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import WhatsappIconSpeedDial from "../whatsappIconSpeedDial";
import { to, verifiVariable } from "../../utils/enums";
import { searchInput } from "../../store/contract/contactListFilters";
import { verifyUUIDv4 } from "../../utils/validations";

const settings = [/* "Editar perfil",  */"Cerrar session"];

//import PopupStateNotifications from "../common/notifications";

/*const notifications = [
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        }
    ]*/

const Menutop = () => {
    const refInputControl = useRef();
    const {
        contract: {
            contractListFilters: { loadingSearchInput, searchInputData }
        }
    } = useSelector((state) => state.root);
    const { user } = useSelector((state) => state.root.session.login);
    const dispatch = useDispatch();
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [searchValue, setSearchValue] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickUserMenu = async (option) => {

        /* if (option === "Editar perfil") {
          handleCloseUserMenu();
          navegate(to.USER_PROFILE)
        } */

        if (option === "Cerrar session") {

            if (!indexedDB.databases) {
                console.warn("La API `indexedDB.databases()` no está soportada en este navegador.");
                return;
            }

            const databases = await indexedDB.databases();
            databases.forEach((db) => {
                if (db.name === "firmaVirtual1") {
                    indexedDB.deleteDatabase(db.name);
                    console.log(`Base de datos "${db.name}" eliminada.`);
                }
            });

            // endSession();

            setTimeout(() => {
                window.location.reload(true);
                // navigate(to.HOME);
            }, 900);

        }

    };

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };


    const handleKeyPressAndBlur = (e) => {
        const verify = e.target.value.split("-").length - 1;

        if (verify === 0) {

            setOpen(true);
            const { token } = user;

            dispatch(searchInput(1, 300, token, { ap_number: e.target.value.toUpperCase().trim().replace(/\s+/g, '') }));
        }

    };

    const handleSearch = (value) => {
        // setSearchValue(value);

        if (!verifiVariable(value)) {

            if (value["modal"] === "SIGNATURE_ANF") {
                navigate(to.ANF_DETAIL, {
                    state: { sContractID: value["sContractID"] },
                });
            } else if (value["sProcess"] === "NORMAL") {
                navigate(to.CONTRACT_DETAIL, { state: value["sContractID"] });
            } else if (value["sProcess"] === "EXPRESS") {
                navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                    state: { sContractID: value["sContractID"] },
                });
            }
        }
    };

    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }

        if (typeof option === "string") return option;

        if (!verifiVariable(option.sTipoContrato)) {
            return `${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma}${option.modal !== "SIGNATURE_ANF" ? " - " + option.sProcess : ""}`;
        } else {
            return `${option.sContractID} - ${option.autoId}`;
        }
    };

    useEffect(() => {
        setLoading(loadingSearchInput);
    }, [loadingSearchInput]);

    useEffect(() => {
        if (!verifiVariable(searchInputData)) {
            const { data } = searchInputData;
            setOptions(data);
        }

    }, [searchInputData]);

    return (
        <div className="top-menu__container">
            <div className="top-menu__logo">
                <Logo className="logo-size--xs" />
            </div>
            <div className="top-menu__user-access">
                <div className="top-menu__searchbar">

                    <AutocompleteMui
                        open={open}
                        onClose={handleClose}
                        getLimitTagsText={2}
                        fullWidth
                        autoHighlight
                        multiple={false}
                        freeSolo
                        options={options}
                        value={searchValue}
                        loading={loading}
                        loadingText="Buscando..."
                        isOptionEqualToValue={(option, value) => option.sContractID === value.sContractID}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;

                            if (!verifiVariable(option.sTipoContrato)) {
                                return (
                                    <Typography
                                        key={key}
                                        {...optionProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClose();
                                            setSearchValue(option);
                                            handleSearch(option);
                                        }}
                                    >
                                        {`${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma} ${option.sTipoFirma}${option.modal !== "SIGNATURE_ANF" ? " - " + option.sProcess : ""}`}
                                    </Typography>
                                );
                            } else {
                                return (
                                    <Typography
                                        key={key}
                                        {...optionProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClose();
                                            setSearchValue();
                                            handleSearch(option);
                                        }}
                                    >
                                        {option.sContractID} - {option.autoId}
                                    </Typography>
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                ref={refInputControl}
                                {...params}
                                onChange={(e) => {
                                    if (![0, 1, 2, 3].includes(e.target.value.length)) {
                                        const getSlice = e.target.value.slice(0, 4);
                                        const verify = getSlice.includes("AP") || getSlice.includes("ap") || getSlice.includes("ASN") || getSlice.includes("asn");
                                        if (verify) handleKeyPressAndBlur(e);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {

                                        const contractID = e.target.value.split(" - ")[0];

                                        if ([verifyUUIDv4(contractID)].includes(true)) {

                                            const splitValue = e.target.value.split(" - ");

                                            if (splitValue[splitValue.length - 1] === "EXPRESS") handleSearch({ sProcess: "EXPRESS", sContractID: contractID });

                                            if (splitValue[splitValue.length - 1] === "NORMAL") handleSearch({ sProcess: "NORMAL", sContractID: contractID });

                                            if (splitValue[splitValue.length - 1] === "FIRMA ANF") handleSearch({ modal: "SIGNATURE_ANF", sContractID: contractID });

                                        } else {
                                            handleKeyPressAndBlur(e);
                                        }

                                    }
                                }}
                                label="Buscar trámite por numero de AP"
                                variant="outlined"
                            />
                        )}
                    />

                </div>
                <div className="user-menu-notif">
                    {/*<PopupStateNotifications notifications={notifications} />*/}
                    {/* <FaCircle className="icon--xxs icon--red new-msg-alert" /> */}
                    {/* <FaBell className="icon--xs icon--grey-dark icon-pointer" /> */}
                    <WhatsappIconSpeedDial />
                </div>

                <div className="user-data-name">
                    <div className="name__container">
                        <strong className="user-name">{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                        <span className="user-rol">Administrator</span>
                    </div>
                    <div onClick={handleOpenUserMenu}>
                        <Avatar
                            style={{ cursor: "pointer" }}
                            alt="User name"
                        />
                    </div>

                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {
                            settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => handleClickUserMenu(setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))
                        }
                    </Menu>

                </div>

            </div>

        </div>
    );
};

export default Menutop;
