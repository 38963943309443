import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


import ContractListHeader from "../ContractListHeader";
import AddProvider from "./molecules/AddProvider";
import { CustomToobarTableList, GeneralListProced } from "../..";
import { setearFecha, to, verifiVariable } from "../../../utils/enums";
import { contractPager, setFilterList } from "../../../store/contract/contactListFilters";

const DocAnfManagement = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractListFilters: { pagerData, filterListData, loading }
        }
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [openForm, setOpenForm] = useState(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [pagination, setPagination] = useState(null);



    const updateData = () => {
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { perPage, currentPage } } = pagerData;
            dispatch(contractPager(currentPage, perPage, token, filterListData));
        }
    }

    useEffect(() => {

        const { token } = user;

        if (!verifiVariable(pagerData)) {
            setDataGrid(pagerData.data);
            setPagination(pagerData.pagination);
        } else {
            if (verifiVariable(pagerData)) {
                dispatch(contractPager(1, 5, token, { signature_modal: state.list }));
                dispatch(setFilterList({ signature_modal: state.list }));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagerData, state]);

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    dataGrid={[...dataGrid].map((item) => {
                        return {
                            sProcess: "ANF",
                            sContractID: item["sContractID"],
                            autoId: item["autoId"],
                            sContractName: item["sContractName"],
                            sTipoContrato: item["sTipoContrato"],
                            sTipoFirma: item["sTipoFirma"],
                            sStatus: item["sStatus"],
                            sPaymentStatus: item["sPaymentStatus"],
                            dContractDT: setearFecha(item["dContractDT"]),
                            withDocument: item["withDocument"],
                            iSignedCount: item["iSignedCount"]
                        };
                    })}
                    title={"Trámites ANF"}
                    subTitle={
                        "Aquí podrás visualizar todos los trámites que tienes creados"
                    }
                    onUpdate={() => updateData()}
                    onCreate={() => navigate(to.ANF_CREATE)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                loadingParent={loading}
                                pagination={pagination}
                                updateList={() => updateData()}
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: "ANF",
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(item["dContractDT"]),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"]
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    return (
        <div className="content-detail__container">
            <AddProvider
                openForm={openForm}
                closeForm={(e) => setOpenForm(e)}
            />

            <ContractListHeader />

            {renderList()}
        </div>
    );
};

export default DocAnfManagement;
