import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

import { Box, Grid, Typography } from '@mui/material';

import LastProceduresCard from '../lastProceduresCard';
import { ProceduresHistoryStyles } from '../../index.css';
import ScreenSizeHook from '../../../../hooks/ScreenSizeHook';

const LastProcedures = ({ data }) => {

    const [lastProcedures, setLastProcedures] = useState([]);
    const styles = ProceduresHistoryStyles();
    const screenSizeHook = ScreenSizeHook();
    
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {

        if (data) {
            const newData = data.filter(ele => {
                return ele.tramitType !== 'FIRMA ANF' && ele.contractType !== 'COMERCIAL'
            });

            const maxIndex = newData.length <= 4 ? newData.length : 4;

            setLastProcedures(newData.slice(0, maxIndex));
        };

    }, [data])

    return (
        <Grid container={!['xs', 'sm', 'md'].includes(screenSizeHook)} spacing={1}>
            {lastProcedures?.length > 0 ? (
                !['xs', 'sm', 'md'].includes(screenSizeHook) ? (
                    <>
                        {lastProcedures.map(ele => (
                            <LastProceduresCard
                                key={ele.contractID}
                                autoID={ele.autoID}
                                contractID={ele.contractID}
                                contractType={ele.contractType}
                                IcontractTypeFeeID={ele.IcontractTypeFeeID}
                                iSignedCount={ele.iSignedCount}
                                iSignerCount={ele.iSignerCount}
                                processType={ele.processType}
                                sPaymentStatus={ele.sPaymentStatus}
                                status={ele.status}
                                tramitType={ele.tramitType}
                            />
                        ))}
                    </>
                ) : (
                    <Box className="slider-container">
                        <Slider {...settings}>
                            {lastProcedures.map(ele => (
                                <LastProceduresCard
                                    key={ele.contractID}
                                    autoID={ele.autoID}
                                    contractID={ele.contractID}
                                    contractType={ele.contractType}
                                    IcontractTypeFeeID={ele.IcontractTypeFeeID}
                                    iSignedCount={ele.iSignedCount}
                                    iSignerCount={ele.iSignerCount}
                                    processType={ele.processType}
                                    sPaymentStatus={ele.sPaymentStatus}
                                    status={ele.status}
                                    tramitType={ele.tramitType}
                                />
                            ))}
                        </Slider>
                    </Box>
                )
            ) : (
                <Typography className={styles.secondaryText}>
                    Aun no tienes trámites creados
                </Typography>
            )}
        </Grid>
    )
}

export default LastProcedures