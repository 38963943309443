import { forwardRef, Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { BsExclamationTriangle } from "react-icons/bs";
import { FaCloudArrowDown } from "react-icons/fa6";

import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

import { ReactComponent as UserIcon } from "../../img/user-icon.svg";
import { ReactComponent as CardIcon } from "../../img/card-icon.svg";
import { ReactComponent as DocReadyToDownload } from "../../img/doc-ready-to-download.svg";
import { ReactComponent as AcceptedDocument } from "../../img/accepted-document.svg";
import { ReactComponent as RejectedDocument } from "../../img/rejected-document.svg";
import { ReactComponent as DocumentToAccept } from "../../img/documento-por-aceptar.svg";
import { ReactComponent as SignedIcon } from "../../img/signed-icon.svg";
import { ReactComponent as UnsignedIcon } from "../../img/unsigned-icon.svg";
import { ReactComponent as RequiredIconStatus } from "../../img/esperando-preaprobacion.svg";
import { ReactComponent as DisagreeIconStatus } from "../../img/preaprobacion-rechazada.svg";
import { ReactComponent as AgreeIconStatus } from "../../img/preaprobacion-aceptada.svg";

import ResendUserContract from "./molecules/resendUserContract";
import { DetailSignatoriesStyles } from "./index.css";
//import EditParticipant from "../editParticipant";
import {
    api,
    docStatus,
    typeUser,
    verifiVariable,
} from "../../../../../utils/enums";
import RejectCommentsModal from "../rejectCommentsModal";
import { theme } from "../../../../../theme/theme";
import { Context } from "../../../../../context/utilsContext";
import {
    aprovedMemberContract,
    downloadMemberContractDoc,
    unAprovedMemberContract,
} from "../../../detailSignatories/services";
import { downloadCompiledDTE } from "../../../../../utils/api";
import { Box, Button, Paper } from "@mui/material";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";

const styledSignatureStatus = {
    zoom: "1.3",
    marginTop: "6px"
};

const stylesStatuSign = {
    marginTop: "6px",
    zoom: "1.3",
};

const stylesPreApproverStatus = {
    marginTop: "6px",
    zoom: "1.3",
};

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomTitle = styled(Typography)((props) => ({
    lineHeight: "1.2",
    fontFamily: ['"PoppinsBold"'].join(","),
    fontSize: '.93rem',
    margin: '0 0 .25rem 0',
    maxWidth: '100%',
    overflowWrap: 'break-word'
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const CustomActionButton = styled(Button)((props) => ({
    width: '1rem',
    height: '3.125rem',
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    borderRadius: "7px",
    padding: '.813rem 1.25rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "& span.MuiButton-startIcon": {
        color: props.theme.palette.white.main,
        marginRight: "0px",
        marginLeft: "0px",
    },
}));

const CustomPaymentActionButton = styled(CustomActionButton)((props) => ({
    width: '65%',
    fontSize: '.875rem',
    "& span.MuiButton-startIcon": {
        marginRight: "8px"
    },
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '65%',
    }
}));

const DetailSignatories = ({
    comentarios,
    member,
    index,
    contractDetail,
    contractMembers,
    contractFiles,
    removeSignner,
    deleteSigner,
    updateContract,
    loading,
    textLoading,
}) => {
    const url = `${api.BASE_URI}/webpay/create/${member["token_payment"]}/${contractDetail[0]["sContractID"]}`;
    const styles = DetailSignatoriesStyles();
    const {
        session: {
            login: { userInfo, user },
        },
    } = useSelector((state) => state.root);
    const { loading: loading1, setLoading } = useContext(Context);
    const [statusContract, setStatusContract] = useState(undefined);
    const [contract, setContract] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [titleErrorAlert, setTitleErrorAlert] = useState(undefined);
    const [msnErrorAlert, setMsnErrorAlert] = useState(undefined);
    const screenSize = ScreenSizeHook();

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTitleErrorAlert(undefined);
        setMsnErrorAlert(undefined);
    };

    const descargarDTE = async (email, sContractID) => {
        try {
        } catch (error) { }
        const { token } = user;

        const {
            data: { status },
        } = await downloadCompiledDTE(sContractID, token, email);

        if (status === "fail") {
            setTitleErrorAlert("Archivo no disponible.");
            setMsnErrorAlert(
                "No se puede descargar el archivo porque no existe."
            );

            setOpen(true);
        }
    };

    const downloadContract = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await downloadMemberContractDoc(
                member["token_download"],
                token,
                contractDetail[0]["sContractID"]
            );

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    const unAprovedDoc = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await unAprovedMemberContract(member["token_disagree"], token);

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    const aprovedDoc = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await aprovedMemberContract(member["token_agree"], token);

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    useEffect(() => {
        if (contractDetail) {
            setStatusContract(contractDetail[0]?.sStatus);
            setContract(contractDetail[0]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractDetail]);

    return (
        <Fragment>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
            >
                <Alert
                    className={styles.muiAlert}
                    onClose={handleCloseSnackbar}
                    severity="success"
                >
                    El link de pago ha sido copiado, puedes enviárselo a el
                    firmante.
                </Alert>
            </Snackbar>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                className={styles.mainContainerDialog}
            >
                <DialogTitle sx={{ textAlign: "center", color: "red" }}>
                    {!verifiVariable(titleErrorAlert)
                        ? titleErrorAlert
                        : "Ah ocurrido un error!"}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {!verifiVariable(msnErrorAlert) ? (
                            msnErrorAlert
                        ) : (
                            <Fragment>
                                Lo sentimos, ah ocurrido un error. Le pedimos
                                disculpas por las molestias causadas y lo
                                invitamos a reportar el error a nuestro equipo
                                de soporte desde el siguiente enlace.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => {
                                        window.open(
                                            "https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0",
                                            "_blank"
                                        );
                                        handleClose();
                                    }}
                                >
                                    <FaWhatsapp
                                        className="icon--white"
                                        style={{
                                            fontSize: 28,
                                            fill: "#5cc858",
                                        }}
                                    />
                                </IconButton>
                            </Fragment>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <div className={styles.mainContainer}>
                <Item className={styles.cardContainer}>
                    {/* <Tooltip
                        title={
                            <Typography
                                variant="subtitle1"
                                color={["white.main"]}
                                gutterBottom
                            >
                                Actualizar datos del participante.
                            </Typography>
                        }
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                    >
                        <IconButton
                            aria-label="settings"
                            sx={{
                                alignSelf: "flex-end",
                            }}
                            onClick={() => updateContract()}
                        >
                            <CachedIcon className="icon--blue-light" />
                        </IconButton>
                    </Tooltip> */}

                    <Box>
                        <UserIcon />
                    </Box>

                    <div id="textInfoParticipans">

                        <CustomTitle
                            color={["primary.light"]}
                        >
                            Participante {1 + index}
                        </CustomTitle>

                        <CustomTitle
                            color={["black.main"]}
                            className="textEmail"
                        >
                            {!verifiVariable(member) ? member["email"] : "No especificado"}
                        </CustomTitle>

                        <CustomTitle
                            color={["black.main"]}
                        >
                            {!verifiVariable(member) ? member["full_name"] : "No especificado"}
                        </CustomTitle>

                        <CustomTitle
                            color={["black.main"]}
                        >
                            {!verifiVariable(member) ? member["phone"] : "No especificado"}
                        </CustomTitle>

                        <CustomTitle
                            color={["black.main"]}
                        >
                            {!verifiVariable(member) ? member["RUT"] : "No especificado"}
                        </CustomTitle>

                        <Box className={styles.roleItem} gutterBottom>
                            {member['rol'] === 0 && member['portion'] === '0.00' &&
                                <Typography className={styles.textRol} variant="h6">
                                    Firmante
                                </Typography>}
                            {member['rol'] === 1 &&
                                <Typography className={styles.textRol} variant="h6">
                                    Firmante
                                </Typography>}
                            {member['rol'] === 0 && member['portion'] !== '0.00' &&
                                <Typography className={styles.textRol} variant="h6">
                                    Pagador Firmante
                                </Typography>}
                            {(member['rol'] === 2 || member['rol'] === 4) &&
                                <Typography className={styles.textRol} variant="h6">
                                    Pre aprobador
                                </Typography>}
                            {member['rol'] === 3 &&
                                <Typography className={styles.textRol} variant="h6">
                                    Pagador
                                </Typography>}
                            {member['rol'] === 5 &&
                                <Typography className={styles.textRol} variant="h6">
                                    Copia
                                </Typography>}
                        </Box>

                        {member["portion"] !== "0.00" && (
                            <Fragment>
                                {member["payment"] === "PENDING" && (
                                    <div className={styles.statusPay}>
                                        <FaExclamationCircle className="icon--blue" />

                                        <CustomTitle
                                            sx={{ fontSize: "0.93rem" }}
                                            color={["primary.main"]}
                                        >
                                            Pendiente de pago
                                        </CustomTitle>
                                    </div>
                                )}

                                {member["payment"] === "PAYOUT" && (
                                    <div className={styles.statusPay}>
                                        <FaExclamationCircle className="icon--blue-light" />

                                        <CustomTitle
                                            sx={{ fontSize: "0.93rem" }}
                                            color={["primary.main"]}
                                        >
                                            Pagado
                                        </CustomTitle>
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {[1, 0].includes(member["rol"]) && (
                            <Fragment>
                                {member["download_draft"] ===
                                    docStatus.ACTIVE &&
                                    member["agree_draft"] ===
                                    docStatus.NOT_ACTIVE &&
                                    member["disagree_draft"] ===
                                    docStatus.NOT_ACTIVE && (
                                        <div
                                            className="flex--center"
                                            style={styledSignatureStatus}
                                        >
                                            <DocReadyToDownload style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}

                                {member["download_draft"] ===
                                    docStatus.SUCCESS &&
                                    member["agree_draft"] ===
                                    docStatus.ACTIVE &&
                                    member["disagree_draft"] ===
                                    docStatus.ACTIVE && (
                                        <div
                                            className="flex--center"
                                            style={styledSignatureStatus}
                                        >
                                            <DocumentToAccept style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}

                                {member["download_draft"] ===
                                    docStatus.SUCCESS &&
                                    member["agree_draft"] ===
                                    docStatus.SUCCESS &&
                                    member["disagree_draft"] ===
                                    docStatus.NOT_ACTIVE && (
                                        <div
                                            className="flex--center"
                                            style={styledSignatureStatus}
                                        >
                                            <AcceptedDocument style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}

                                {member["download_draft"] ===
                                    docStatus.SUCCESS &&
                                    member["agree_draft"] ===
                                    docStatus.NOT_ACTIVE &&
                                    member["disagree_draft"] ===
                                    docStatus.SUCCESS && (
                                        <div
                                            className="flex--center"
                                            style={styledSignatureStatus}
                                        >
                                            <RejectedDocument />
                                        </div>
                                    )}

                                {(member["download_draft"] === undefined ||
                                    member["agree_draft"] === undefined ||
                                    member["disagree_draft"] ===
                                    undefined) && (
                                        <div className="flex--start status_tag status--pending">
                                            {
                                                <BsExclamationTriangle className="icon--xs margin-right--sm" />
                                            }{" "}
                                            Sin información
                                        </div>
                                    )}
                            </Fragment>
                        )}

                        {[1, 0].includes(member["rol"]) && (
                            <Fragment>
                                {
                                    member["sign_status"] === "SIGNED" && (
                                        <div
                                            className="flex--center"
                                            style={stylesStatuSign}
                                        >
                                            <SignedIcon style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )
                                }

                                {
                                    ["DELIVERED", "SIGNED_PENDING"].includes(
                                        statusContract
                                    ) &&
                                    [
                                        "NOTHING",
                                        "NOTIFIED",
                                        "VIEW",
                                        "DECLINED",
                                        "BOUNCED",
                                        "FORWARDED",
                                        "CANCELLED",
                                    ].includes(member["sign_status"]) && (
                                        <div
                                            className="flex--center"
                                            style={stylesStatuSign}
                                        >
                                            <UnsignedIcon style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )
                                }
                            </Fragment>
                        )}

                        {[2, 4].includes(member["rol"]) && (
                            <Fragment>
                                {!verifiVariable(contract) &&
                                    contract["sPreAprobado"] ===
                                    "REQUIRED" && (
                                        <div
                                            className="flex--center"
                                            style={stylesPreApproverStatus}
                                        >
                                            <RequiredIconStatus style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}

                                {!verifiVariable(contract) &&
                                    contract["sPreAprobado"] ===
                                    "BEFORE_DISAGREE" && (
                                        <div
                                            className="flex--center"
                                            style={stylesPreApproverStatus}
                                        >
                                            <DisagreeIconStatus style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}

                                {!verifiVariable(contract) &&
                                    contract["sPreAprobado"] ===
                                    "BEFORE_AGREE" && (
                                        <div
                                            className="flex--center"
                                            style={stylesPreApproverStatus}
                                        >
                                            <AgreeIconStatus style={{
                                                width: (screenSize === 'xs' || screenSize === 'sm') ? '9rem' : '10rem'
                                            }} />
                                        </div>
                                    )}
                            </Fragment>
                        )}

                        <CardActions disableSpacing>
                            {loading1 && <CircularProgress />}
                            {/* TODO verificar si solo el owner puede aciones botones de aceptar y rechazar */}

                            {!loading1 &&
                                /* (!verifiVariable(userInfo) && userInfo['iProfileID'] === contractDetail[0]['iContractOwner']) && */
                                ["REVIEW", "CREATED", "REJECTED"].includes(
                                    statusContract
                                ) &&
                                [0, 1, 4].includes(member["rol"]) &&
                                userInfo["iProfileID"] === typeUser.ADMIN && (
                                    <Stack direction="row" spacing={1}>
                                        {
                                            contractDetail[0]["withDocument"] === 1 &&
                                            (
                                                <IconButton
                                                    onClick={() => downloadContract()}
                                                >
                                                    <Tooltip title="Descargar" arrow>
                                                        <CloudDownloadOutlined className="icon--grey-dark-light" />
                                                    </Tooltip>
                                                </IconButton>
                                            )
                                        }

                                        {[docStatus.SUCCESS].includes(
                                            member["download_draft"]
                                        ) &&
                                            [docStatus.ACTIVE].includes(
                                                member["disagree_draft"]
                                            ) &&
                                            [docStatus.ACTIVE].includes(
                                                member["agree_draft"]
                                            ) && (
                                                <Fragment>
                                                    <IconButton
                                                        onClick={() =>
                                                            unAprovedDoc()
                                                        }
                                                    >
                                                        <Tooltip
                                                            title="Rechazar"
                                                            arrow
                                                        >
                                                            <CancelOutlinedIcon className="icon--red" />
                                                        </Tooltip>
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => aprovedDoc()}
                                                    >
                                                        <Tooltip
                                                            title="Aceptar"
                                                            arrow
                                                        >
                                                            <CheckCircleOutline className="icon--green" />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Fragment>
                                            )}
                                    </Stack>
                                )}
                        </CardActions>
                    </div>
                </Item>

                <Box className="actionParticipantsButtons">

                    <Box
                        className={styles.contentActionParticipants}
                        sx={{ justifyContent: "space-between" }}
                    >
                        {/* CASOS BOTONES PRE APROBADORES */}
                        {
                            [2].includes(member["rol"]) &&
                            ["CREATED"].includes(contract?.["sStatus"]) &&
                            [
                                "REQUIRED",
                                "BEFORE_DISAGREE",
                                "BEFORE_REVIEW",
                            ].includes(contract["sPreAprobado"]) && (
                                // (!verifiVariable(member) && member['download_draft'] !== docStatus.SUCCESS && member['agree_draft'] !== docStatus.SUCCESS) &&
                                <Fragment>
                                    {/* <div>
                                        <EditParticipant
                                            updateContract={() =>
                                                updateContract()
                                            }
                                            member={member}
                                            contractMembers={
                                                contractMembers
                                            }
                                            numParticipant={1 + index}
                                            idContract={
                                                contractDetail[0][
                                                "sContractID"
                                                ]
                                            }
                                            loading={(e) => loading(e)}
                                            textLoading={(e) =>
                                                textLoading(e)
                                            }
                                        />
                                    </div> */}

                                    <CustomActionButton>
                                        <ResendUserContract
                                            updateContract={() =>
                                                updateContract()
                                            }
                                            contractMembers={
                                                contractMembers
                                            }
                                            contractFiles={contractFiles}
                                            member={member}
                                        />
                                    </CustomActionButton>
                                </Fragment>
                            )
                        }

                        {/* CASOS BOTON PAGADOR */}

                        {
                            [3, 4, 0].includes(member["rol"]) &&
                            ["APPROVED"].includes(contract?.["sStatus"]) &&
                            ["PENDING", "PARTIALLY_PAID"].includes(
                                contract["sPaymentStatus"]
                            ) &&
                            member["portion"] !== "0.00" && (
                                <Tooltip
                                    title={
                                        <Typography
                                            variant="subtitle1"
                                            color={["white.main"]}
                                        >
                                            Pagar trámite.
                                        </Typography>
                                    }
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <CustomPaymentActionButton
                                        onClick={() => {
                                            handleClickSnackbar();
                                            window.open(
                                                url,
                                                "_blank"
                                            );
                                        }}
                                        startIcon={<CardIcon />}
                                        variant="contained"
                                    >
                                        Pagar
                                    </CustomPaymentActionButton>
                                </Tooltip>
                            )
                        }

                        {
                            [3, 4, 0].includes(member["rol"]) &&
                            ["PAIDOUT"].includes(contract?.["sPaymentStatus"]) &&
                            member["portion"] !== "0.00" && (
                                <Tooltip
                                    title={
                                        <Typography
                                            variant="subtitle1"
                                            color={["white.main"]}
                                            gutterBottom
                                        >
                                            Descargar DTE
                                        </Typography>
                                    }
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <CustomActionButton
                                        onClick={() => {
                                            descargarDTE(
                                                member["email"],
                                                contract["sContractID"]
                                            );
                                        }}
                                        startIcon={<FaCloudArrowDown />}>
                                    </CustomActionButton>
                                </Tooltip>
                            )
                        }

                        {
                            !verifiVariable(contract) && [
                                "REVIEW",
                                "CREATED",
                                "REJECTED",
                                "APPROVED",
                                "FINISHED",
                                "SIGNED_PENDING",
                            ].includes(contract?.["sStatus"]) &&
                            !verifiVariable(contract) && [
                                "REQUIRED",
                                "BEFORE_DISAGREE",
                                "BEFORE_REVIEW",
                                "BEFORE_AGREE",
                                "NONE",
                            ].includes(contract["sPreAprobado"]) &&
                            !verifiVariable(contract) &&
                            ["PENDING", "PARTIALLY_PAID", "PAIDOUT"].includes(contract["sPaymentStatus"]) &&
                            (
                                <div className={styles.contentActionButtoms}>
                                    {/* CASOS BOTON COMENTARIOS */}

                                    {
                                        ["CREATED", "REJECTED", "REVIEW"].includes(contract?.["sStatus"]) &&
                                        !verifiVariable(comentarios) &&
                                        comentarios.filter((item) =>
                                            item["SignerName"] === member["full_name"]
                                        ).length >= 1 && (
                                            <div>
                                                <RejectCommentsModal
                                                    member={member}
                                                    index={index}
                                                    comenmts={[...comentarios].filter(
                                                        (item) =>
                                                            item["SignerName"] ===
                                                            member["full_name"]
                                                    )}
                                                />
                                            </div>
                                        )
                                    }

                                    {/* CASOS BOTONES FIRMANTES */}

                                    {
                                        [0, 1, 4].includes(member["rol"]) &&
                                        (["NONE"].includes(contract["sPreAprobado"])
                                            ? true
                                            : ["BEFORE_AGREE"].includes(
                                                contract["sPreAprobado"]
                                            )) &&
                                        !verifiVariable(member) &&
                                        (member["download_draft"] !==
                                            docStatus.SUCCESS ||
                                            member["agree_draft"] !==
                                            docStatus.SUCCESS) && (
                                            <Fragment>
                                                {/* {
                                                    ["CREATED", "REVIEW"].includes(
                                                        contract?.["sStatus"]
                                                    ) && (
                                                        <div>
                                                            <EditParticipant
                                                                updateContract={() =>
                                                                    updateContract()
                                                                }
                                                                member={member}
                                                                contractMembers={
                                                                    contractMembers
                                                                }
                                                                numParticipant={1 + index}
                                                                idContract={
                                                                    contractDetail[0][
                                                                    "sContractID"
                                                                    ]
                                                                }
                                                                loading={(e) => loading(e)}
                                                                textLoading={(e) =>
                                                                    textLoading(e)
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                } */}

                                                {
                                                    ["REVIEW"].includes(
                                                        contract?.["sStatus"]
                                                    ) && (
                                                        <CustomActionButton
                                                            style={{
                                                                background:
                                                                    theme.palette.primary
                                                                        .light,
                                                            }}
                                                        >
                                                            <ResendUserContract
                                                                updateContract={() =>
                                                                    updateContract()
                                                                }
                                                                contractMembers={
                                                                    contractMembers
                                                                }
                                                                contractFiles={
                                                                    contractFiles
                                                                }
                                                                member={member}
                                                            />
                                                        </CustomActionButton>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }

                                </div>
                            )
                        }
                    </Box>
                </Box>

            </div>
        </Fragment>
    );
};

export default DetailSignatories;