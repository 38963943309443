import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { from, of } from "rxjs";
import { mergeMap, map, catchError, toArray } from "rxjs/operators";

import { ReactComponent as UpdateIconV1 } from "../../assets/icons/update-icon-v1.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { Context } from "../../context/utilsContext";
import { useLocation } from "react-router-dom";
import { to } from "../../utils/enums";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import { theme } from "../../theme/theme";
import useExportToExcel from "../dashboard/general-list-proced/hooks/exportToExcel";
import { getDetailContract, getDetailContractANF } from "../../utils/api";

const ActionsWhiteButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 12,
    padding: "6px 12px",
    lineHeight: 1.5,
    width: '5.36rem',
    height: '2.68rem',
    backgroundColor: props.theme.palette.white.main,
    color: props.theme.palette.primary.light,
    border: `1px solid ${props.theme.palette.primary.light}`,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.white.main,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.white.main,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 14,
        width: '10rem',
    }
}));

const ActionsBlueButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 12,
    padding: "6px 12px",
    lineHeight: 1.5,
    width: '5.36rem',
    height: '2.68rem',
    color: props.theme.palette.white.main,
    backgroundColor: props.theme.palette.primary.light,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        backgroundColor: props.theme.palette.primary.light,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 14,
        width: '10rem',
    }
}));

const CustomToobarTableList = ({
    onUpdate,
    onCreate,
    title,
    subTitle,
    onDisableCreate,
    dataGrid
}) => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractListFilters: { loading }
        }
    } = useSelector((state) => state.root);
    const { setShow } = useContext(Context);
    const { pathname } = useLocation();
    const screenSize = ScreenSizeHook();
    const [signersList, setSignersList] = useState([]);
    const exportToExcel = useExportToExcel(dataGrid, title);
    const exportSignersToExcel = useExportToExcel(signersList, "Firmantes de historial de trámites");

    // eslint-disable-next-line
    const signersDownload = useCallback(() => {
        const { token } = user;

        setSignersList([]); // Reiniciamos la lista de firmantes

        if (!Array.isArray(dataGrid) || dataGrid.length === 0) return;

        from(dataGrid).pipe(
            mergeMap((contract) => {
                const apiCall = contract["sProcess"] !== "ANF"
                    ? getDetailContract(token, contract["sContractID"])
                    : getDetailContractANF(token, contract["sContractID"]);

                return from(apiCall).pipe(
                    map(({ status, message, data }) => {
                        const firmantes = status === "success"
                            ? (message?.firmantes || data?.firmantes || []).map((item) => ({
                                ...item,
                                autoId: contract["autoId"],
                                sContractID: contract["sContractID"],
                            }))
                            : [];
                        return firmantes;
                    }),
                    catchError(() => of([])) // Manejo de errores: devolvemos una lista vacía en caso de error
                );
            }),
            toArray() // Recopilamos todos los resultados en un array
        ).subscribe((results) => {
            const flattenedSigners = results.flat();
            // console.log("signersArray: ", flattenedSigners);

            setSignersList(flattenedSigners);

            setTimeout(() => {
                exportSignersToExcel();
            }, 1000);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        flexDirection: "column",
                    }}
                >
                    <Typography fontFamily={"PoppinsBold"}>{title}</Typography>
                    <Typography
                        variant={"caption"}
                        fontFamily={"PoppinsRegular"}
                    >
                        {subTitle}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "flex-end",
                        margin: '1rem 0 0 0'
                    }}
                >
                    {/* <ActionsWhiteButton
                        startIcon={['xs', 'sm', 'md'].includes(screenSize) ? null : <CloudDownloadIcon />}
                        onClick={() => signersDownload()}
                        sx={{ margin: "0 1rem 0 0" }}
                    >
                        Firmantes
                    </ActionsWhiteButton> */}

                    <ActionsWhiteButton
                        disabled={loading}
                        startIcon={['xs', 'sm', 'md'].includes(screenSize) ? null : <CloudDownloadIcon />}
                        onClick={() => exportToExcel()}
                        sx={{ margin: "0 1rem 0 0" }}
                    >
                        Descargar
                    </ActionsWhiteButton>

                    {pathname !== to.CONTRACT_APROVED && (
                        <ActionsWhiteButton
                            disabled={loading}
                            startIcon={['xs', 'sm', 'md'].includes(screenSize) ? null : <FilterIcon />}
                            onClick={() => setShow()}
                            sx={{ margin: "0 1rem 0 0" }}
                        >
                            Filtros
                        </ActionsWhiteButton>
                    )}

                    <ActionsWhiteButton
                        disabled={loading}
                        startIcon={['xs', 'sm', 'md'].includes(screenSize) ? null : <UpdateIconV1 />}
                        onClick={() => onUpdate()}
                        sx={{ margin: "0 1rem 0 0" }}
                    >
                        Actualizar
                    </ActionsWhiteButton>

                    <ActionsBlueButton disabled={onDisableCreate} onClick={() => onCreate()}>
                        Crear trámite
                    </ActionsBlueButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CustomToobarTableList;
