import {
    useState,
    useEffect,
    useCallback,
    Fragment
} from "react";
import { gridColumns } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
    ContractListHeader,
    CustomToobarTableList,
    GeneralListProced
} from "../..";
import {
    setearFecha,
    verifiVariable
} from "../../../utils/enums";
import ModalAlert from "../../floats/ModalAlert";
import { contractPager, setFilterList } from "../../../store/contract/contactListFilters";

const ManageProcedures = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            loading,
        },
        contract: {
            contractListFilters: { pagerData, filterListData, loading: loadingFilters },
            tramites: {
                loadingTramits,
                loadingTypeTramits,
            },
        },
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();

    // eslint-disable-next-line
    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});
    const [pagination, setPagination] = useState(null);

    const updateData = () => {
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { perPage, currentPage } } = pagerData;
            dispatch(contractPager(currentPage, perPage, token, filterListData));
        }
    }

    useEffect(() => {

        const { token } = user;

        if (!verifiVariable(pagerData)) {
            setDataGrid(pagerData.data);
            setPagination(pagerData.pagination);
        } else {
            if (verifiVariable(pagerData)) {
                dispatch(contractPager(1, 5, token));
                dispatch(setFilterList({}));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagerData]);

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    dataGrid={[...dataGrid].map((item) => {
                        return {
                            sProcess: item["sProcess"],
                            sContractID: item["sContractID"],
                            autoId: item["autoId"],
                            sContractName: item["sContractName"],
                            sTipoContrato: item["sTipoContrato"],
                            sTipoFirma: item["sTipoFirma"],
                            sStatus: item["sStatus"],
                            sPaymentStatus: item["sPaymentStatus"],
                            dContractDT: setearFecha(
                                item["dContractDT"]
                            ),
                            withDocument: item["withDocument"],
                            iSignedCount: item["iSignedCount"],
                        };
                    })}
                    title={"Historial de trámites."}
                    subTitle={"Resumen de historial de trámites."}
                    onUpdate={() => {
                        setTextSpinner("Actualizando listado de trámites...");
                        updateData();
                    }}
                    onCreate={() => ""}
                    onDisableCreate={true}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                loadingParent={loadingFilters}
                                pagination={pagination}
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: item["sProcess"],
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(
                                            item["dContractDT"]
                                        ),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"],
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits, gridColumns]);

    return (
        <div className="content-detail__container">

            <ContractListHeader />

            {/* {(loading || loadingTramits || loadingTypeTramits || loading1 || loading2) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )} */}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManageProcedures;
