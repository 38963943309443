import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import Participants from "../molecules/participants";
import AddDocuments from "../molecules/addDocuments";
//import StepsANF from "../molecules/stepsANF";

import WelcomeOptionsContent from "../molecules/welcomeOptionsContent";
import {
    asyncFileToBase64,
    getErrorContractCreation,
    to,
    typeUser,
    verifiVariable,
    verifyRol,
} from "../../../../utils/enums";
import PreviewDocument from "../molecules/previewDocument";
import { clearForm, createANFTramit, saveTramitANFInfo } from "../../../../store/formANFTramit";
import { SignAuthorizationStyles } from "../index.css";
import ErrorAlert from "../molecules/errorAlert";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../spinners/Spinner";
import Agreement from "../molecules/agreement";
import SignatureType from "../molecules/signatureType";
import ModalContractUploadError from "../../../modalContractUploadError";
import UserManuals from "../../../userManuals";
import ModalContractCreatedAndContractSteps from "../../../modalContractCreatedAndContractSteps";
import ContractName from "../../../contractName";
import { theme } from "../../../../theme/theme";
import { Container } from "@mui/material";
import { saveParticipants } from "../../../../store/contract/frequentParticipants";
//import AttachedCDV from "../../../attachedCDV";

const ActionsButton = styled(Button)((props) => ({
    width: "47%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "1rem",
    padding: "9px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const ButtonInfo = styled(Button)((props) => ({
    width: "100%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "1rem",
    padding: "9px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    fontFamily: ['"PoppinsBold"'].join(","),
    [theme.breakpoints.up('sm')]: {
        width: '50%'
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const AnfCreateProcedure = () => {
    const {
        formANFTramit: {
            firms,
            document,
            participants,
            signatureType,
            signatureTypeList,
            status,
            message,
            uploadProgress,
            agrement,
            statusMergeDoc,
            attachedCDV,
            contractName
        },
        session: {
            login: { user, userInfo },
        },
    } = useSelector((state) => state.root);
    // eslint-disable-next-line
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    // eslint-disable-next-line
    const [showError, setShowError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [textError, setTextError] = useState([]);
    const [phrases, setPhrases] = useState([]);
    const [titleError, setTitleError] = useState("");
    const dispatch = useDispatch();
    const styles = SignAuthorizationStyles({ document });
    const [openModal, setOpenModal] = useState(0);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);

    /* const disabledButttom = useCallback(() => {
        const arrayData = [...participants];

        const verifyPay = arrayData.find((item) =>
            item.rol.includes("Pagador")
        );

        const verifySigner = arrayData.find((item) =>
            item.rol.includes("Firmante")
        );

        return (
            verifiVariable(signatureType) ||
            signatureTypeList.length === 0 ||
            verifiVariable(document) ||
            arrayData.length === 0 ||
            verifiVariable(verifyPay) ||
            verifiVariable(verifySigner) ||
            Object.keys({ ...firms }).length === 0 ||
            (!verifiVariable(userInfo) &&
                userInfo["iProfileID"] === typeUser.ADMIN &&
                verifiVariable(agrement))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        document,
        participants,
        signatureType,
        firms,
        agrement,
        signatureTypeList,
    ]); */

    const signatureTypeName = signatureType?.['name'];

    const verifyData = () => {
        let sigCountTramit1 = 0;
        let sigCountTramit2 = 0;

        if (!verifiVariable(signatureTypeList) && signatureTypeList.length > 0) {
            [...signatureTypeList].forEach(element => {
                if (element["iSignCount"] === 2) sigCountTramit2 = 2;
                if (element["iSignCount"] === 1) sigCountTramit1 = 1;
            });
        }

        const arrayData = [...participants];

        const verifyPay = arrayData.find((item) =>
            item.rol.includes("Pagador")
        );
        const verifySigner = arrayData.find((item) =>
            item.rol.includes("Firmante")
        );
        const isFullPay = arrayData
            .map(signer => Number(signer.pay))
            .reduce((acc, sum) => acc + sum, 0);

        const usersAsigned = arrayData
            .filter(signer =>
                signer.rol.includes('Firmante')
            );
        const firmsAsigned = Object.entries(firms)
            .map(ele => ele.map(ele => ele.rut))
            .map(ele => ele[1]);
        const isNameBusiness = arrayData.some(ele => {
            return ele.signerLegal === 'true' && ele.nameBusiness === ''
        });

        const isAllSignersAsigned = usersAsigned
            .every(ele => firmsAsigned.includes(ele.rut));
        const errors = []
        if (signatureTypeList.length === 0) errors.push('Debes seleccionar el trámite')
        if (userInfo && userInfo["iProfileID"] === typeUser.ADMIN && !agrement) errors.push('Debes seleccionar un convenio');
        if (arrayData.length === 0) errors.push('Debes agregar un firmante');
        if (!verifyPay) errors.push('Debes asignar al menos un pagador');
        if (!document) errors.push('Debes subir tu documento');
        if (isFullPay !== 100) errors.push('Debes asignar el 100% del pago');
        if (!verifySigner) errors.push('Debes agregar al menos un firmante');
        //if (Object.keys({ ...firms }).length === 0) errors.push('Debes asignar firmas');
        if (isNameBusiness) errors.push('Asigna la empresa representada');
        if ((
            signatureTypeName === 'Contrato de arriendo' ||
            signatureTypeName === 'Promesa de compraventa' ||
            signatureTypeName === 'Mandato de administración'
        ) && !attachedCDV) {
            /*  signatureTypeName should call tramitTypeName*/
            // errors.push('Debes notificar si va adjunto el certificado de dominio');
        }

        // if (Object.keys({ ...firms }).length === 0) errors.push('No se han asignado firmas');
        if (!isAllSignersAsigned) errors.push('Faltan firmas por asignar');

        if (verifiVariable(signatureTypeList) || signatureTypeList.length === 0) errors.push('Tipo de firma no valido para ' + signatureTypeName);

        if (sigCountTramit1 === 0 || sigCountTramit2 === 0) {

            if (sigCountTramit1 === 1) {
                if (usersAsigned.length > 1) errors.push('Solo esta permitido un firmante para este trámite');
            }

            if (sigCountTramit2 === 2) {
                if (usersAsigned.length < 2) errors.push('Solo estan permitidos 2 o mas firmantes para este trámite');
            }

        }

        return errors
    };

    const asyncPdfForSignrequest = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if ([null, undefined, ""].includes(document)) return;

                const dataFirms = { ...firms };

                const pdfDoc = await PDFDocument.load(document);

                // Embed the Helvetica font
                const helveticaFont = await pdfDoc.embedFont(
                    StandardFonts.Helvetica
                );

                // Get the first page of the document
                const pages = pdfDoc.getPages();

                Object.values(dataFirms).forEach((item, index) => {
                    const signedPage = pages[item["page"] - 1];

                    // Draw a string of text diagonally across the first page
                    if (item["signer"] === true) {
                        signedPage.drawText(
                            item["text"],
                            Object.assign(
                                { ...item },
                                {
                                    font: helveticaFont,
                                    color: rgb(1, 1, 1),
                                    y: signedPage.getHeight() - item["y"],
                                }
                            )
                        );
                    } else {
                        const size = 10;

                        signedPage.drawText(
                            item["text"],
                            Object.assign(
                                { ...item },
                                {
                                    size: size,
                                    font: helveticaFont,
                                    color: rgb(1, 1, 1),
                                    y: signedPage.getHeight() - item["y"],
                                }
                            )
                        );

                        signedPage.drawText(
                            `Aprobador ${item["num"]}: ${item["textTootip"]}`,
                            Object.assign(
                                { ...item },
                                {
                                    x: item["x"] + 27,
                                    size: size,
                                    font: helveticaFont,
                                    y: signedPage.getHeight() - item["y"],
                                }
                            )
                        );
                    }
                });

                const pdfBytes = await pdfDoc.save();

                const file = new File([pdfBytes], "document", {
                    type: "application/pdf",
                });

                resolve(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const saveData = async () => {
        const verifiedData = verifyData();
        setErrorsUpload(verifiedData);
        if (verifiedData.length !== 0) {
            setOpenModalErrors(true);
            return
        }

        try {
            setLoading(true);
            const { token } = user;

            const arrayData = [...participants];

            let signedCount = [...arrayData].filter((item) => item["rol"].includes("Firmante")).length

            setTextLoading("Creando Trámite, por favor espere");

            const fileSigReqest = await asyncPdfForSignrequest();

            const fileBase64 = await asyncFileToBase64(fileSigReqest);

            const contractType = () => {
                if (signedCount >= 2) {
                    return signatureTypeList.find((item) => item["iSignCount"] === 2)["iContractTypeFeeID"];
                } else {
                    return signatureTypeList.find((item) => item["iSignCount"] === 1)["iContractTypeFeeID"];
                }
            };

            const data = {
                ownerType: "LEGAL",
                signedCount,
                paymentService: 0,
                contractTypeFeeID: contractType(),
                callback: "https://firmavirtual.com/callback.php",
                agreementId: !verifiVariable(agrement)
                    ? agrement["agreementId"]
                    : 0,
                priceId: 0,
                // attachedCDV,
                contractName: contractName,
                signers: arrayData
                    .sort((a, b) => {
                        return (a["rol"].includes("Aprobador") - b["rol"].includes("Aprobador"))
                    })
                    .map((item) => {
                        const firmante = {
                            rol: verifyRol(item["rol"]),
                            payment: "0.00",
                            type: "NATURAL",
                            portion: item["pay"],
                            full_name: item["fullName"],
                            email: item["email"],
                            rutId: !verifiVariable(item["rut"]) ? item["rut"] : item["passport"],
                            phone: Number(item["phone"].replace('+', '')),
                            order: item["signerOrder"],
                            signerLegal:
                                item["signerLegal"] === "true" ? true : false,
                            authNeed: item["rol"].includes("Aprobador")
                                ? false
                                : true,
                        };

                        if (item["signerLegal"] === "true") {
                            Object.assign(firmante, {
                                nameBusiness: item["nameBusiness"],
                                rutBusiness: item["rutBusiness"],
                            });
                        }

                        return firmante;
                    }),
                file: fileBase64.replace("data:application/pdf;base64,", ""),
            };

            dispatch(saveParticipants([...participants]));

            dispatch(createANFTramit(token, data));

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const handleDataClick = () => {
        saveData();
    };

    useEffect(() => {

        if (status === "success" && !verifiVariable(message)) {
            navigate(to.CONTRACTS_CREATED_SUCCESS, {
                state: {
                    sContractID: message,
                    tramitType: 'anf'
                }
            });
            dispatch(clearForm());
            setLoading(false);
        };

        if (status === "fail") {

            const errors = getErrorContractCreation(message?.details, 'anf')

            if (errors.length === 0) {
                setShowError((e) => 1 + e);
                setTextError(
                    ["Por favor, verifica los datos, inténtalo nuevamente y, si el error persiste, comunícate con el administrador del sistema."]
                );
                setTitleError("Ha surgido un problema al crear el trámite.");
            };

            if (errors.length > 0) {
                setShowError((e) => 1 + e);
                setTextError([errors]);
                setTitleError("Ha surgido un problema al crear el trámite.");
            };

            setLoading(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, message]);

    useEffect(() => {
        if (uploadProgress !== 0) {
            setTextLoading("Limpiando archivo " + uploadProgress + "%");
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (statusMergeDoc === "fail") {
            setTitleError("Documento dañado.");
            setTextError(
                ["Ha ocurrido un problema con el documento. Por favor, verifíquelo e inténtelo nuevamente."]
            );

            setShowError((e) => 1 + e);
        }
    }, [statusMergeDoc]);

    useEffect(() => {
        if (signatureTypeName !== 'Contrato de arriendo' &&
            signatureTypeName !== 'Promesa de compraventa' &&
            signatureTypeName !== 'Mandato de administración') {
            dispatch(
                saveTramitANFInfo({
                    indexe: "attachedCDV",
                    value: null,
                })
            )
        }
    }, [signatureTypeName, dispatch]);

    useEffect(() => {
        let windowWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            if (window.innerWidth !== windowWidth) {

                if (!verifiVariable(document)) {

                    setShowError((e) => 1 + e);

                    setTextError(["Hemos detectado un cambio en el tamaño del navegador, por esta razón debe reasignar las firmas."]);

                    setTitleError("Se ha detectado un cambio en el tamaño del navegador.");

                }

                windowWidth = window.innerWidth;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!verifiVariable(signatureType) && signatureType["value"] === "AUTORIZACIÓN NOTARIAL") {
            setErrorsUpload(["AUTORIZACION ONLINE DE VIAJES DE MENORES DE EDAD NO ESTA PERMITIDA BAJO NINGUNA MODALIDAD ONLINE EN NUESTRA PLATAFORMA"]);
            setOpenModalErrors(true);
        }
    }, [signatureType]);

    return (
        <Container maxWidth={'xl'} sx={{ marginTop: '1rem' }}>

            <Box className={styles.mainContent}>
                <Grid container>
                    {isMobile && (
                        <Grid item xs={12} md={6} lg={7}>
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.document}`}
                            >
                                {!verifiVariable(document) && (
                                    <PreviewDocument onPhrases={(value) => setPhrases(value)} />
                                )}
                            </Item>
                        </Grid>
                    )}

                    <ModalContractUploadError
                        openModalErrors={openModalErrors}
                        setOpenModalErrors={setOpenModalErrors}
                        data={errorsUpload}
                        modalIcon={'warnIcon'}
                    />

                    <ModalContractCreatedAndContractSteps
                        tramitType={'anf'}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />

                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={5}
                        sx={{ zIndex: 99 }}
                        id="section-action"
                    >
                        <Item className={`${styles.contenItem} ${styles.formItem}`}>
                            {loading && (
                                <Spinner
                                    loading={true}
                                    type="bar"
                                    text={textLoading}
                                    opacity={true}
                                />
                            )}

                            <ErrorAlert
                                msn={textError}
                                title={titleError}
                                errors={showError}
                            />

                            <UserManuals download={'https://drive.google.com/file/d/1ocHP_0qun7uxyniTR4O-8DxcOCTtA-gJ/view?usp=sharing'} />

                            <Agreement />

                            <SignatureType />

                            <Participants />

                            {/* {
                            (
                                signatureTypeName === 'Contrato de arriendo' ||
                                signatureTypeName === 'Promesa de compraventa' ||
                                signatureTypeName === 'Mandato de administración'
                            ) && (
                                <AttachedCDV signatureType={'ANF'} />
                            )
                        } */}

                            <ContractName signatureType={'anf'} />

                            <AddDocuments
                                onResetPhrases={() => setPhrases([])}
                                loadingModaldocs={(e) => setLoading(e)}
                                textLoadingModaldocs={(e) => setTextLoading(e)}
                            />

                            <Box className={styles.stepContainerActionsButtoms}>
                                <ActionsButton
                                    onClick={() => dispatch(clearForm())}
                                    variant="contained"
                                    color="primary"
                                >
                                    Limpiar
                                </ActionsButton>

                                <ActionsButton
                                    disabled={phrases.length !== 0}
                                    onClick={handleDataClick}
                                    variant="contained"
                                    color="primary"
                                >
                                    Finalizar
                                </ActionsButton>
                            </Box>
                        </Item>

                        <Item className={`${styles.contenItem} ${styles.formItem}`}>
                            <Box
                                className={`${styles.stepContainerActionsButtoms} ${styles.managProc}`}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                // className={styles.titleFV}
                                >
                                    Conoce los pasos de validación y notificación
                                    que llegan al correo de los participantes
                                </Typography>

                                <ButtonInfo
                                    onClick={() =>
                                        setOpenModal((value) => 1 + value)
                                    }
                                    variant="outlined"
                                    color="primary"
                                >
                                    Ingresa aquí
                                </ButtonInfo>
                            </Box>
                        </Item>
                    </Grid>

                    {!isMobile && (
                        <Grid item xs={12} sm={6} lg={7}>
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.document}`}
                            >
                                {!verifiVariable(document) &&
                                    !isMobile ? (
                                    <PreviewDocument onPhrases={(value) => setPhrases(value)} />
                                ) : (
                                    <WelcomeOptionsContent />
                                )}
                            </Item>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default AnfCreateProcedure;
