import React, { useContext, useEffect, useState } from "react";
import { colorsTheme } from "../../utils/enums";
import { Text } from "..";
import { Avatar, Grid } from "@mui/material";
import image1 from "../../assets/img/bg_login.jpg";
import image2 from "../../assets/img/paper_plane.png";
import image3 from "../../assets/logo/logo_color.svg";
import { useDispatch } from "react-redux";
import { setFilters } from "../../store/contract/contactListFilters";
import { Context } from "../../context/utilsContext";

/**
 * Component in charge of showing the counts of procedures
 * @param bgColor counter background color
 * @param count number of procedures 
 * @param procedure procedure name 
 * @param avatar  if you have an avatar
 * @param imageAvatar avatar image type 
 * @returns component
 */
const Counterbystatus = ({ bgColor, count, procedure, avatar = false, imageAvatar = null, value }) => {

    const [countAvalible, setCountAvalible] = useState(null);
    const dispatch = useDispatch();
    const { setShow } = useContext(Context);

    let imgAvatar = null;

    const cardColor = () => {
        switch (bgColor) {
            case "info":
                return colorsTheme.CUSTOM_INFO_PRIMARY;
            case "danger":
                return colorsTheme.CUSTOM_DANGER_PRIMARY;
            case "warning":
                return colorsTheme.PRIMARY_WARNING;
            case "primary":
                return colorsTheme.PRIMARY_LIGHT;
            case "secondary":
                return colorsTheme.SECONDARY;
            case "tertiary":
                return colorsTheme.TERTIRARY;
            case "quaternary":
                return colorsTheme.QUATERNARY_LIGHT;
            case "senary":
                return colorsTheme.SENARY;
            default:
                return colorsTheme.PRIMARY_LIGHT;
        }
    };

    const titleStyles = {
        color: "white.main",
        fontWeight: "700",
        fontSize: "1.2rem",
        fontFamily: "PoppinsRegular"
    };

    const subTitleStyles = {
        color: "white.main",
        fontWeight: "700",
        fontSize: "1.1rem",
        fontFamily: "PoppinsRegular",
        alignSelf: "flex-end",
        lineHeight: 1
    };

    const containerInfo = {
        padding: "7px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "baseline",
        alignContent: "flex-start",
        cursor: 'pointer'
    }

    const divStyle = {
        backgroundColor: cardColor(),
        marginLeft: 7
    }

    switch (imageAvatar) {
        case 1:
            imgAvatar = image1;
            break;
        case 2:
            imgAvatar = image2;
            break;
        case 3:
            imgAvatar = image3;
            break;
        default:
            imgAvatar = image1;
            break;
    }

    const handleClick = () => {
        dispatch(setFilters({
            filterBy: 'sStatus',
            filters: value
        }));
        setShow(true)
    };

    useEffect(() => {
        if (count) {
            setCountAvalible(count)
        };
    }, [count]);

    return (
        <div
            className="counter-status__container"
            style={divStyle}
            onClick={handleClick}
        >
            <Grid container>
                <Grid item xs={avatar ? 8 : 12} sx={containerInfo}>
                    <Text sx={titleStyles} align="left" variant="h3">
                        {countAvalible ? count : '0'}
                    </Text>
                    <Text sx={subTitleStyles} align="left" variant="h6">
                        {procedure}
                    </Text>
                </Grid>
                {avatar && (
                    <Grid item xs={4}>
                        <Avatar src={imgAvatar} sx={{ width: 84, height: 84 }} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default Counterbystatus;
