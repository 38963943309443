import { createSlice } from "@reduxjs/toolkit";

import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: 'contract list filter',
    initialState: {
        filterBy: null,
        filters: null,
        loading: false,
        loadingSearchInput: false,
        message: "",
        pagerData: null,
        infoData: null,
        searchInputData: null,
        filterListData: null,
        dataWidgets: null
    },
    reducers: {
        FILTER_DATA_WIDGETS: (state, action) => {
            state.dataWidgets = action.payload.message;
            state.loading = false;
        },
        FILTER_LIST_DATA: (state, action) => {
            state.filterListData = action.payload;
            state.loading = false;
        },
        FILTER_BY: (state, action) => {
            state.filterBy = action.payload.filterBy;
            state.filters = action.payload.filters;
        },
        PURGE_STATE: (state, action) => {
            state.filterBy = null;
            state.filters = null;
            state.pagerData = null;
            state.infoData = null;
            state.filterListData = null;
            state.dataWidgets = null;
            state.searchInputData = null;
            state.loading = false;
        },
        FILTER_SEARCH_INPUT_SUCCESS: (state, action) => {
            state.searchInputData = {
                data: action.payload.message.data,
                pagination: action.payload.message.pagination
            };
            state.loadingSearchInput = false;
        },
        FILTER_PAGE_LIST_SUCCESS: (state, action) => {
            state.pagerData = {
                data: action.payload.message.data,
                pagination: action.payload.message.pagination
            };
            state.loading = false;
        },
        FILTER_PAGE_LIST_REQUEST: (state, action) => {
            state.loading = true;
            state.message = "";
        },
        FILTER_SEARCH_INPUT_REQUEST: (state, action) => {
            state.loadingSearchInput = true;
            state.message = "";
        },
        WIDGETS_REQUEST: (state, action) => {
            state.loading = true;
            state.message = "";
        },
        FILTER_PAGE_LIST_FAIL: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
        FILTER_SEARCH_INPUT_FAIL: (state, action) => {
            state.loadingSearchInput = false;
            state.message = action.payload;
        },
        INFO_PAGE_LIST_SUCCESS: (state, action) => {
            state.infoData = {
                data: action.payload.message.data,
                pagination: action.payload.message.pagination
            };
            state.loading = false;
        },
    }
});

export const {
    INFO_PAGE_LIST_SUCCESS,
    FILTER_DATA_WIDGETS,
    FILTER_LIST_DATA,
    FILTER_PAGE_LIST_REQUEST,
    WIDGETS_REQUEST,
    FILTER_PAGE_LIST_SUCCESS,
    FILTER_PAGE_LIST_FAIL,
    FILTER_SEARCH_INPUT_REQUEST,
    FILTER_SEARCH_INPUT_SUCCESS,
    FILTER_SEARCH_INPUT_FAIL,
    FILTER_BY,
    PURGE_STATE
} = slice.actions;

export const searchInput = (page, limit, token, filter = {}) => {
    return apiCallStart({
        url: `/api/v2/contract/report/${page}/${limit}`,
        data: filter,
        method: "POST",
        onStart: FILTER_SEARCH_INPUT_REQUEST.type,
        onSuccess: FILTER_SEARCH_INPUT_SUCCESS.type,
        onError: FILTER_SEARCH_INPUT_FAIL.type,
        authorization: token
    });
};

export const getDataWidgets = (token) => {
    return apiCallStart({
        url: `/api/v2/contract/widgets`,
        data: {},
        method: "GET",
        onStart: WIDGETS_REQUEST.type,
        onSuccess: FILTER_DATA_WIDGETS.type,
        onError: FILTER_PAGE_LIST_FAIL.type,
        authorization: token
    });
};

export const infoPager = (page, limit, token, filter = {}) => {
    return apiCallStart({
        url: `/api/v2/contract/report/${page}/${limit}`,
        data: filter,
        method: "POST",
        onStart: null,
        onSuccess: INFO_PAGE_LIST_SUCCESS.type,
        onError: null,
        authorization: token
    });
};

export const contractPager = (page, limit, token, filter = {}) => {
    return apiCallStart({
        url: `/api/v2/contract/report/${page}/${limit}`,
        data: filter,
        method: "POST",
        onStart: FILTER_PAGE_LIST_REQUEST.type,
        onSuccess: FILTER_PAGE_LIST_SUCCESS.type,
        onError: FILTER_PAGE_LIST_FAIL.type,
        authorization: token
    });
};

export const setFilterList = (filterData) => {
    return { type: FILTER_LIST_DATA.type, payload: filterData }
};

export const setFilters = (filterData) => {
    return { type: FILTER_BY.type, payload: filterData }
};

export const deleteFilters = () => {
    return { type: PURGE_STATE.type }
};

export default slice.reducer;