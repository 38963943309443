import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { verifiVariable } from "../../../utils/enums";

export const SignAuthorizationStyles = makeStyles((theme) => {
    return createStyles({
        mainContent: {
            display: "flex",
            flexGrow: 1,
            marginLeft: ".7rem",
            "& div#section-action": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            },
        },
        itemContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 30px 0px 30px",
            "& hr": {
                width: "100%",
                borderBottomWidth: "unset",
                borderColor: "rgba(165, 165, 165, 1)",
            },
            "& svg.rest-icon": {
                marginTop: "80px",
                marginBottom: "17px",
            },
            "& svg.rest-success-icon": {
                marginTop: "30px",
            },
        },
        mainTitle: {
            fontFamily: "PoppinsBold !important",
            textAlign: "center !important",
            fontSize: "1.15rem !important",
            marginBottom: "10px !important",
            marginTop: "50px !important",
        },
        infoText: {
            fontFamily: "PoppinsRegular !important",
            marginTop: "15px !important",
            textAlign: "justify",
            color: "#434343",
            "& span": {
                fontFamily: "PoppinsBold",
                color: "#434343",
            },
        },
        warningText: {
            fontSize: "0.90rem !important",
            textAlign: "justify",
            margin: "7px 0px 0px 0px !important",
            fontFamily: "PoppinsBold !important",
            color: "#898989",
        },
        videoContent: {
            marginTop: "-70px",
            width: "317px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            alignSelf: "center",
            "& video": {
                background:
                    "linear-gradient(#ccc 0 0) padding-box, linear-gradient(120deg, #00ADEA, #004F9F) border-box",
                borderRadius: "10px",
                border: "13px solid transparent",
                width: "100%",
            },
            "& div.content-info": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                position: "relative",
                bottom: "227px",
                width: "291px",
                borderRadius: "10px 10px 0px 0px",
                // background: "linear-gradient(50deg, blue, red)",
                background: "linear-gradient(50deg, transparent, black)",
                // background: "linear-gradient(50deg, transparent, gray, transparent, black)",
                height: "53px",
                zIndex: "1",
                "& h6": {
                    fontFamily: "PoppinsBold",
                    color: theme.palette.white.main,
                    fontSize: "0.78rem",
                    marginTop: "15px",
                },
                "& h6.played": {
                    alignSelf: "center",
                },
                "& h6.timer": {
                    position: "absolute",
                    left: "10px",
                    cursor: "pointer",
                    marginTop: "14px",
                },
                "& svg.removeVideoButtom": {
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                    marginTop: "5px",
                },
            },
            "& svg.vectorTopLeft": {
                top: "115px",
                position: "relative",
                zIndex: "1",
                left: "23px",
            },
            "& svg.vectorTopRight": {
                top: "70px",
                position: "relative",
                zIndex: "1",
                left: "250px",
            },
            "& svg.vectorDownLeft": {
                bottom: "122px",
                position: "relative",
                zIndex: "1",
                left: "23px",
            },
            "& svg.vectorDownRight": {
                bottom: "168px",
                position: "relative",
                zIndex: "1",
                left: "250px",
            },

            "& svg.action-camera": {
                bottom: "208px",
                position: "relative",
                zIndex: "2",
                left: "39%",
                cursor: "pointer",
            },
        },
        mainTitleError: {
            marginTop: "0px",
        },
        errorInfoText: {
            fontSize: "1rem",
            marginTop: "5px",
            marginBottom: "30px",
            textAlign: "center",
            color: theme.palette.black.main,
            "& span": {
                fontFamily: "PoppinsRegular",
                color: theme.palette.primary.light,
                fontSize: ".9rem",
                fontWeight: "700",
            },
        },
        contacUsContent: ({ contactMarginTop }) => ({
            marginTop: contactMarginTop,
            "& h6": {
                color: theme.palette.black.main,
                textAlign: "center",
                fontSize: ".85rem",
            },
            "& h6:nth-child(1), h6:nth-child(5)": {
                fontFamily: "PoppinsRegular",
            },
            "& h6:nth-child(4)": {
                fontFamily: "PoppinsBold",
            },
            "& div.icons": {
                display: "flex",
                justifyContent: "space-evenly",
                "& div.icon-contact": {
                    cursor: "pointer",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.white.main,
                    width: "40px",
                    height: "40px",
                    borderRadius: "19px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                },
            },
            "& div.num-contact": {
                marginTop: "12px",
                display: "flex",
                justifyContent: "center",

                '& > a': {
                    fontSize: '.8rem'
                }
            },
        }),
        mainTitleSuccess: {
            marginBottom: "25px",
        },
        successInfoText: {
            textAlign: "justify",
        },
        document: ({ document }) => ({
            height: "100%",
            paddingTop: !verifiVariable(document) ? "0px" : "",
        }),
        contenItem: {
            paddingTop: "40px",
        },
        stepContainerActionsButtoms: {
            width: `100%`,
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "80px",
            flexDirection: "row",
            paddingLeft: "45px",
            paddingRight: "45px",
            margin: "45px 0 0"
        },

        managProc: {
            paddingBottom: "45px",
            flexDirection: "column",
            alignItems: "center",
            "& h6": {
                textAlign: "center",
                fontFamily: "PoppinsBold",
                fontSize: "0.9rem",
            },
        },
        contentDocuments: {
            display: "flex",
            flexDirection: "column",
        },
        title: {
            color: theme.palette.black.main,
            fontFamily: "PoppinsBold",
            fontSize: "0.95rem",
        },
        subTitle: {
            color: theme.palette.black.main,
            fontFamily: "PoppinsBold",
            fontSize: ".8rem",
        },
        textRadioButtom: {
            marginBottom: "0px",
            fontSize: "0.95rem",
            fontFamily: "PoppinsRegular",
        },
        spacinContent: {
            marginLeft: "45px",
            marginRight: "45px",
        },
        formItem: {
            boxShadow: "12px 0px 39px rgba(156, 174, 191, 0.2)",
            borderRadius: "7px",
        },
        infoItem: {
            paddingRight: "15px",
            paddingLeft: "15px",
            boxShadow: "unset",
            borderRadius: "0px 7px 7px 0px",
            "& > div": {
                marginBottom: "35px",
            },
        },
        roleItem: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0.438rem 0 0!important',
        },
        titleDFV: {
            marginTop: "10px",
            marginBottom: "20px",
            fontFamily: "PoppinsBold",
            textAlign: "center",
            "& > span": {
                fontFamily: "PoppinsBold",
                fontStyle: "italic",
                fontSize: "1.5rem",
                color: theme.palette.primary.light,
            },
        },
        titleFV: {
            marginBottom: "55px",
            fontFamily: "PoppinsBold",
            textAlign: "center",
            "& > span": {
                fontFamily: "PoppinsBold",
                fontStyle: "italic",
                fontSize: "1.5rem",
                color: theme.palette.primary.light,
            },
        },
        welcomeOptionsContent: {
            display: "flex",
            justifyContent: "space-evenly",
            "&  > div": {
                display: "flex",
                flexDirection: "column",
                width: "151px",
                alignItems: "center",
                " & > svg": {
                    width: "80px",
                    height: "80px",
                    marginBottom: "17px",
                },
                " & > h6": {
                    fontSize: "0.90rem",
                    fontFamily: "PoppinsBold",
                    textAlign: "center",
                },
            },
        },
        contactUs: {
            paddingTop: "26px",
            textAlign: "center",
            " & > h6": {
                fontFamily: "PoppinsBold",
            },
            "& h6:first-child": {
                fontSize: "0.90rem",
            },
            "& h6:nth-child(2)": {
                fontSize: "0.65rem",
                color: theme.palette.black.main,
            },
            "& > span": {
                fontSize: "0.70rem",
            },
        },
        titleAddDiag: {
            color: theme.palette.primary.light,
            fontFamily: "PoppinsBold",
            fontSize: "1rem",
            alignSelf: "flex-start",
        },
        titleFormAddDiag: {
            marginBottom: ".5rem",
            "& > h6": {
                color: theme.palette.black.main,
                fontFamily: "PoppinsBold",
                fontSize: "0.81rem",
            }
        },
        buttomsRol: {
            justifyContent: "space-around",
            "& label": {
                marginLeft: "0px",
                marginRight: "0px",

                "& span": {
                    padding: "1px",
                },
            },
        },
        rolIcon: {
            display: 'flex',
            /* justifyContent: 'center',
            "& > .css-j204z7-MuiFormControlLabel-root": {
                "& > span": {
                    padding: '0 .25rem 1rem .25rem'
                }
            }, */
        },
        contentFomAddpart: {
            width: "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 23px 0px 23px",
            margin: "45px 13px 37px 13px",
        },
        modalHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2.5rem',
            alignItems: 'center',
            position: 'relative',
            "& > .closeIcon": {
                position: 'absolute',
                top: '-2.5rem',
                right: '-2rem'
            }
        },
        emailInput: {
            "& input, div": {
                borderRadius: "7px",
            },
        },
        errorText: {
            textAlign: "right",
        },
        deleteIcon: {
            marginRight: "0px !important",
        },
        navigationControls: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
        },
        pdfViewer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            borderStyle: "solid",
            borderColor: theme.palette.primary.light,
            borderRadius: "5px",
            "& svg.delete-icon": {
                position: "relative",
                top: "10px",
                right: "12px",
                float: "right",
                cursor: "pointer",
                fill: "red",
            },
        },
        contentTracker: {
            padding: "unset !important",
            overflow: "unset !important",
        },
        contractDetailsHeader: {
            backgroundColor: "transparent !important",
            borderRadius: "0px !important",
            marginBottom: "0px !important",
        },
        numberTitle: {
            display: "flex",
            alignItems: "center",
            margin: "55px 0 25px 0",
            "& h6": {
                lineHeight: "1.2",
                "& span": {
                    color: "#A5A5A5",
                    fontFamily: "PoppinsRegular",
                },
            },
        },
        stepNumbers: {
            display: "flex",
            width: "35px",
            height: "35px",
            justifyContent: "center",
            borderRadius: "7px",
            alignItems: "center",
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.light,
            fontSize: "1.2rem",
            fontFamily: "PoppinsBold",
        },
        detailTextTitle: {
            fontFamily: "PoppinsBold",
            fontSize: "0.90rem",
            marginLeft: "15px",
        },
        detailTextDetail: {
            fontFamily: "PoppinsRegular",
            color: theme.palette.gray.main,
            fontSize: "0.85rem",
            marginLeft: "50px",
            marginTop: "10px",
        },
        orderInfo: {
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '10px',
            padding: '1rem',
            lineHeight: '.5rem',
            "& h6": {
                fontSize: "0.85rem",
                fontFamily: "PoppinsBold",
                margin: '0 !important',
                padding: '0 !important'
            },

            "& .service-info": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between !important",
                margin: '0 !important'
            },

            "& .service-info-total": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: '0 !important',
                '& > :first-of-type': {
                    marginRight: '1rem !important'
                }
            }
        },
        infoDoc: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignItems: "center",
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '10px',
            padding: '1rem 0',
            "& div:first-child": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "50px",
            },
            "& div:first-child svg": {
                marginRight: "5px",
            }
        },
        buttonsDocTramit: {
            display: "flex",
            justifyContent: "center",
            "& svg:nth-child(2)": {
                marginLeft: "10px",
            },
        },
        optionsTramit: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
        },
        infoDoc_buttons: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        buttonTramit: {
            margin: 5,
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `1px solid ${theme.palette.primary.xlight}`,
            borderRadius: "5px",
            height: '2.25rem',
            width: '11.5rem',
            backgroundColor: theme.palette.white.main
        },
        buttonTramit2: {
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `none`,
            borderRadius: "5px",
            backgroundColor: theme.palette.primary.light,
            height: '2.25rem',
            width: '11.5rem',
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            }
        },
        detailTextNameDoc: {
            marginLeft: "0px",
            fontSize: "0.70rem",
            color: theme.palette.primary.main
        },
        bottonTitle: {
            marginBottom: "0px",
            marginTop: "100px",
            fontSize: "0.75rem",
            "& span": {
                fontSize: "0.9rem",
                textDecoration: "underline",
            },
        },
        contactInfo: {
            textAlign: "center",
            paddingBottom: "40px",
            "& span": {
                color: theme.palette.gray.main,
            },
        },
        mainContentParticipants: ({ screenSizeHook }) => ({
            maxHeight: screenSizeHook === "sm" ? "815px" : "740px",
            maxWidth: ["xs", "sm"].includes(screenSizeHook) ? '100%' : '22rem',
            paddingRight: '.813rem',
            overflowY: "auto",
            "& h6": {
                lineHeight: "1.2",
                fontFamily: "PoppinsBold",
                fontSize: ".875rem",
            },
            "& div": {
                borderRadius: "6px",
            },
            "& :not(:first-child, button, h6, #textInfoParticipans, .actionParticipantsButtons, svg)": {
                marginTop: "1.8rem",
            },
            "& .actionParticipantsButtons": {
                marginTop: '.938rem'
            },
            "&::-webkit-scrollbar": {
                width: '.813rem',
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: '#f1f1f1',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '.438rem',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        cardFirmUser: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            padding: "1.625rem 1.75rem",
            boxShadow: "0px 4px 22px 1px rgba(0, 79, 159, 0.03)",
            "& > div#textInfoParticipans": {
                width: '70%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                marginLeft: '1.56rem',
                whiteSpace: 'break-spaces',
                overflowWrap: 'anywhere',
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: '0',
                    marginTop: '.844rem',
                    alignItems: 'center'
                }
            },
            "& :first-child": {
                color: theme.palette.primary.light + " !important",
            },
            "& h6": {
                color: theme.palette.black.main,
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: "column",
            }
        },
        textXs: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            padding: "0px",
            "& h5": {
                marginBottom: "0px",
            },
        },
        cardTypeSignatureXs: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.2rem !important",
            height: '18.5rem',
            [theme.breakpoints.up('md')]: {
                flexDirection: "column",
                alignItems: "flex-start",
            }
        },

        ownerData_Info_Box: {
            margin: '0 0 0 1.3rem',
            display: 'flex',
            flexDirection: 'column'
        },

        ownerData_Info_textBox: {
            margin: '.75rem 0 0 0 !important',
            display: 'flex',
            flexDirection: 'column'
        },
        cardDocumentXs: {
            padding: "10px !important",

            "& div.container-info": {
                margin: "12px 20px !important",
                padding: "0px 10px",

                border: `1px solid ${theme.palette.primary.xxxlight}`,
                backgroundColor: theme.palette.primary.xxxxlight,
                borderRadius: '10px',

                "& div.name-dovc": {
                    width: "100%",
                    display: "flex",
                    marginTop: 20,
                    alignItems: "center",

                    "& svg": {
                        flex: "0 0 20%",
                        maxWidth: "20%",
                        zoom: 1.5
                    },

                    "& h6": {
                        textAlign: "justify",
                        fontSize: ".7rem",
                        fontFamily: "PoppinsRegular",
                        flex: "0 0 80%",
                        maxWidth: "80%",
                    }
                },

                "& div.container-icons": {
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    marginTop: "15px !important",

                    "& svg": {
                        cursor: "pointer",
                        margin: "5px !important"
                    }
                }
            },

            "& div.container-icons": {
                marginTop: "0px !important"
            }
        },
        /* cardDocumentXs: {
            height: "220px",
            padding: "0px",
            paddingRight: "15px",
            "& h6:first-child": {
                paddingTop: "40px",
                marginLeft: "15px",
            },
            "& :nth-child(2)": {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
                margin: "0px",
                fontFamily: "PoppinsRegular",
                color: "#434343",
                "& svg": {
                    marginLeft: "15px",
                    marginRight: "5px",
                },
            },
            "& :nth-child(3)": {
                marginTop: "38px !important",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "0px",
                "& svg": {
                    marginTop: "0px !important",
                },
                "& svg:first-child": {
                    marginRight: "7px",
                },
            },
        }, */
        contentFooterXs: {
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& :not(span)": {
                fontFamily: "PoppinsBold",
            },
            "& h6:first-child": {
                fontSize: "1.15rem",
                color: theme.palette.primary.main,
                marginBottom: "10px",
            },
            "& :nth-child(2)": {
                color: theme.palette.black.main,
                fontSize: "0.8rem",
            },
        },
        appBarBottom: {
            position: "fixed",
            top: "auto",
            bottom: 0,
            zIndex: "unset",
            background: theme.palette.white.main,
            "& .MuiTabs-root": {
                width: "100%",
            },
            "& button": {
                fontSize: "0.66rem",
                textTransform: "unset !important",
                fontFamily: "PoppinsBold",
                color: theme.palette.primary.main,
            },
        },
        textInfoPay: {
            color: theme.palette.gray.main,
        },
        signatoriesList: {
            display: "flex",
            justifyContent: "center",
        },
        mainContainerDialog: {
            "& div.MuiDialogContent-root": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            },
            "& div.MuiPaper-root": {
                borderRadius: "10px",
            },
            "& h6:nth-child(2)": {
                fontFamily: "PoppinsBold",
                fontSize: "1.1rem",
            },
            "& h6:nth-child(3)": {
                fontFamily: "PoppinsRegular",
                fontSize: ".9rem",
                color: theme.palette.black.main,
                "& span": {
                    color: theme.palette.primary.light,
                },
            },
        },
        dragableBox: ({ layerX, layerY }) => ({
            borderStyle: "solid",
            borderColor: "rgb(167, 167, 167)",
            borderWidth: 2,
            padding: "5px 10px",
            borderRadius: 7,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: `${layerX}px`,
            top: `${layerY}px`,
            background: "transparent",
            cursor: "move",
            "& p": {
                fontSize: "2rem",
            },
        }),
        boxIcon: {
            position: "absolute",
            top: -12,
            fill: "red",
            zIndex: 999,
            cursor: "pointer",
        },
        textIcon: ({ iconColor }) => ({
            fill: iconColor,
            position: "absolute",
            cursor: "pointer",
            top: -12
        }),
        canvasContainer: {
            width: "100%",
            height: "auto",
            overflow: "auto",
            background: "#333",
            textAlign: "center",
            border: "solid 3px",
        },
        contentActionParticipants: {
            width: "100%",
            display: "flex",
            margin: "0px 0px 0px 0px !important",
            "& svg": {
                marginBottom: "5px",
                marginTop: "5px !important",
                fill: theme.palette.white.main,
            },
        },
        contentDialogVideo: {
            display: "flex",
            flexDirection: "column",
        },
        mainTitleDni: {
            marginTop: "35px !important",
            fontFamily: "PoppinsBold !important",
        },
        subTitleDni1: {
            marginBottom: "30px !important",
            marginTop: "35px !important",
            fontFamily: "PoppinsRegular !important",
            textAlign: "justify",
            "& span": {
                fontFamily: "PoppinsRegular !important",
                color: theme.palette.black.main,
            },
        },
        captureContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            position: "relative",
            "& svg": {
                position: "absolute",
                opacity: "0.2",
            },
        },
        fileMergePreviewDniCapture: {
            width: "300px",
            height: "350px",
            backgroundColor: "#EAF7FB",
            border: "1px dashed #00ADEA",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            "& img": {
                width: "85%",
                height: "85%",
                borderRadius: "7px",
            },
            /* "& div:nth-child(3)": {
                width: "158px !important",
                height: "229px !important",
                backgroundColor: "#EAF7FB !important",
    
                "& object:first-child, img:first-child": {
                    height: "135px !important",
                },
            }, */
        },
        conatinerCloseButtomPreview: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            /* alignItems: "baseline",
            "& svg": {
                margin: "9px",
            },
            "& h6": {
                marginLeft: "45px",
                marginTop: "0px !important",
                marginBottom: "0px !important",
            }, */
        },
        closeText: {
            fontFamily: "PoppinsBold !important",
            color: theme.palette.black.main,
        },
        loadinVideoText: {
            fontFamily: "PoppinsRegular !important",
            color: theme.palette.black.main,
        },
        infoOwner: {
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
            "& svg": {
                marginRight: "20px",
            },
            "& > div:nth-child(2)": {
                marginRight: "50px",
            },
            "& h6": {
                fontSize: "0.85rem",
                fontFamily: "PoppinsBold",
            },
            "& p": {
                fontSize: "0.9rem",
                fontFamily: "PoppinsRegular",
                color: "#434343",
            },
        },
        constentPlayVideo: {
            height: "333px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            aspectRatio: "1 / 1",
            overflow: "hidden",
            "& > video": {
                width: "74%",
                height: "74%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)"
                // border: "2px solid red",
            },
            "& > svg": {
                position: "absolute",
                // bottom: "-37px",
                alignSelf: "center",
                zIndex: "2",
            },
            "& > div.content-info": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                position: "relative",
                top: "193px",
                width: "74%",
                borderRadius: "10px 10px 0px 0px",
                // background: "linear-gradient(50deg, blue, red)",
                background: "linear-gradient(50deg, transparent, black)",
                // background: "linear-gradient(50deg, transparent, gray, transparent, black)",
                height: "53px",
                zIndex: "1",
                "& h6": {
                    fontFamily: "PoppinsBold",
                    color: theme.palette.white.main,
                    fontSize: "0.78rem",
                    marginTop: "15px",
                    alignSelf: "center",
                },
            },
        },
        titleVideoSign: {
            textAlign: "center",
            fontFamily: "PoppinsBold",
            color: theme.palette.black.main,
            fontSize: "1.1rem",
            marginBottom: "15px",
        },
        textFooter: {
            marginTop: "15px",
            textAlign: "center",
            "& > p:nth-child(1)": {
                "& span": {
                    fontFamily: "PoppinsBold",
                    color: theme.palette.primary.main,
                    fontSize: ".9rem",
                },
            },
            "& > p:nth-child(3)": {
                fontFamily: "PoppinsBold",
                fontSize: ".9rem",
            },

            "& > p:nth-child(6)": {
                fontFamily: "PoppinsBold",
                color: theme.palette.primary.main,
                fontSize: ".9rem",
            },
        },
        modalDocsContent: ({ screenSizeHook }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "20rem",
            backgroundColor: theme.palette.white.main,
            borderRadius: ".5rem",
            boxShadow: 24,
            maxWidth: '800px',
            padding: '1.5rem 2.733rem',
            "& > div.body-modal-content": {
                overflow: "auto",
                width: "auto",
                height: "38.5rem",
                [theme.breakpoints.up('lg')]: {
                    margin: '0 auto',
                    height: "42.5rem",
                }
            },
            [theme.breakpoints.up('lg')]: {
                width: '65%',
                margin: '0 auto'
            }
        }),
        modalDocsContent_closeIcon: {
            position: 'absolute',
            right: '1rem'
        },
        fileButomsContainer: ({ screenSizeHook }) => ({
            width: "100% !important",
            display: "flex",
            justifyContent: "center",
            flexDirection: screenSizeHook === "xs" ? "column" : "row",
            flexWrap: "wrap",
        }),
        modalMergeFiles__title__box: {
            margin: '3.5rem 0 0 0',
            textAlign: 'center',
            maxWidth: '1200px',
            [theme.breakpoints.up('lg')]: {
                width: '100%',
                textAlign: 'start'
            }
        },
        modalMergeFiles__title: {
            fontSize: '.93rem',
            fontFamily: 'PoppinsBold',
            color: theme.palette.black.secondary,
        },
        modalMergeFiles__subTitle: {
            fontSize: '.867rem',
            fontFamily: 'PoppinsRegular',
            color: theme.palette.gray.main,
        },
        modalMergeFiles__textBody__box: {
            width: '100%',
            margin: '2.6rem 0 0 0',
            maxWidth: '1200px',
        },
        modalMergeFiles__textBody__primaryText: {
            fontSize: '.8rem',
            fontFamily: 'PoppinsRegular',
            textAlign: 'justify'
        },
        modalMergeFiles__textBody__secondaryText: {
            fontSize: '.8rem',
            fontFamily: 'PoppinsBold',
            color: theme.palette.primary.main,
            textAlign: 'justify',
            margin: '1.5rem 0 0 0'
        },
        modalMergeFiles__textBody__tertiaryText: {
            fontSize: '.867rem',
            fontFamily: 'PoppinsBold',
            color: theme.palette.black.secondary,
        },
        fileMergePreview: ({ screenSizeHook }) => ({
            width: "100%",
            height: "275px",
            backgroundColor: "#EAF7FB",
            border: "1px dashed #00ADEA",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: '1.8rem auto 0 auto',
            padding: '0 0 1.5rem 0',
            "& div:nth-child(3)": {
                width: "158px !important",
                height: "229px !important",
                backgroundColor: "#EAF7FB !important",

                "& object:first-child, img:first-child": {
                    height: "135px !important",
                },

                "& div": {
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'unset !important',
                    paddingTop: 'unset !important',
                    paddingLeft: 'unset !important'
                }
            },
            [theme.breakpoints.up('lg')]: {
                width: '12rem',
                margin: '1.8rem 1rem 0 1rem',
            }
        }),
        folderIconContainer: {
            width: "100%",
            height: "9rem",
            background: "#EAF7FB",
            border: "1px dashed #00ADEA",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            marginTop: "2.5rem",
            [theme.breakpoints.up('lg')]: {
                width: '12rem',
                height: "18.22rem",
                margin: '1.8rem 1rem 0 1rem',
            }
        },
        containerHeader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '1rem 0',
            border: `solid 1px ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.xxlight,
            '& > .box-icon': {
                padding: '0 .5rem 0 1rem'
            },
            '& > .box-text': {
                padding: '0 1rem 0 .5rem',
                textAlign: 'justify',
                '& > p': {
                    fontSize: '0.8rem',
                    fontFamily: 'PoppinsRegular',
                    '& > span': {
                        fontSize: '0.8rem',
                        fontFamily: 'PoppinsBold',
                        color: theme.palette.black.main
                    }
                }
            }
        }
    });
});