import { forwardRef, Fragment } from "react";
import { useForm } from "react-hook-form";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import { ExpressProcessStyles } from "../../index.css";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ActionsButton = styled(Button)((props) => ({
    width: "47%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "1rem",
    padding: "9px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const AddSign = ({ setOpenSigModal, openSigModal, signatory, signatories }) => {
    const styles = ExpressProcessStyles();

    const { getValues, register, reset } = useForm({
        defaultValues: {
            selectedSign: "",
        },
    });

    const handleClose = () => {
        signatory(getValues("selectedSign"));
        setOpenSigModal(false);
        reset();
    };

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={openSigModal}
                onClose={handleClose}
                maxWidth="xs"
                className={styles.mainContainerDialog}
            >
                <DialogTitle sx={{ textAlign: "center" }}>
                    ¿Quién debe firmar aquí?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Selecciona el firmante que quieres agregar al documento.
                    </DialogContentText>

                    <List dense className={styles.signatoriesList}>
                        <RadioGroup>
                            {signatories
                                .map((item, index) =>
                                    Object.assign({ ...item, sign: index })
                                )
                                .map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index + item["email"]}
                                            value={JSON.stringify(item)}
                                            control={
                                                <Radio
                                                    {...register(
                                                        "selectedSign"
                                                    )}
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: `${item["textColor"]} !important`,
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    color={[item["textColor"]]}
                                                >
                                                    {item["fullName"].length >= 16 ? item["fullName"].substr(0, 15) + '...' : item["fullName"]}
                                                </Typography>
                                            }
                                        />
                                    );
                                })}
                        </RadioGroup>
                    </List>
                </DialogContent>

                <DialogActions
                    style={{ justifyContent: "center", paddingBottom: "20px" }}
                >
                    <ActionsButton
                        onClick={() => handleClose()}
                        variant="contained"
                        color="primary"
                    >
                        Agregar
                    </ActionsButton>
                    {/* <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={() => setOpenSigModal(false)}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Agregar"
                        color="primary"
                        onClick={() => handleClose()}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                            marginLeft: "30px !important",
                        }}
                    /> */}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default AddSign;
