import React, { useEffect, useState } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';

import Slider from "react-slick";

import { CounterByStatus } from "..";
import { theme } from '../../theme/theme';
import { getDataWidgets } from '../../store/contract/contactListFilters';
import { useLocation } from 'react-router-dom';
import { verifiVariable } from '../../utils/enums';

/**
 * Component in charge of showing, list of process counters
 * @param  props properties required for the component
 * @returns 
 */

const ContractListHeader = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const {
        session: {
            login: { user },
        },
        contract: {
            contractListFilters: { dataWidgets },
            /* contractCreator: {
                contractListActive
            } */
        },
        // formANFTramit: { allAnfTramit }
    } = useSelector(state => state.root);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [WidgetsData, setWidgetsData] = useState([]);
    // const [fields, setFields] = useState([]);

    const getSum = (total, num) => total + num;

    const tramitsTypeArray = ["SIGNATURE_ANF", "NORMAL", "EXPRESS", "ALL_TRAMITS"];


    const fields = [
        {
            color: "danger",
            title: "Rechazados",
            value: "REJECTED",
            counter: WidgetsData.find((item) => item["status"] === "REJECTED") ? WidgetsData.find((item) => item["status"] === "REJECTED")["count"] : 0
        },
        {
            color: "info",
            title: "Creados",
            value: "CREATED",
            counter: WidgetsData.find((item) => item["status"] === "CREATED") ? WidgetsData.find((item) => item["status"] === "CREATED")["count"] : 0
        },
        {
            color: "warning",
            title: "En revisión",
            value: "REVIEW",
            counter: WidgetsData.find((item) => item["status"] === "REVIEW") ? WidgetsData.find((item) => item["status"] === "REVIEW")["count"] : 0
        },
        {
            color: "senary",
            title: "Pendientes de pago",
            value: "sPaymentStatus",
            counter: [...WidgetsData].filter((item) => ["PENDING", "PARTIALLY_PAID"].includes(item["status"])).length > 0 ? [...WidgetsData].filter((item) => ["PENDING", "PARTIALLY_PAID"].includes(item["status"])).map((item) => item["count"]).reduce(getSum, 0) : 0// WidgetsData.find((item) => ["PENDING", "PARTIALLY_PAID"].includes(item["status"])) ? (WidgetsData.find((item) => item["status"] === "PENDING")["count"] + WidgetsData.find((item) => item["status"] === "PARTIALLY_PAID")["count"]) : 0
        },
        {
            color: "tertiary",
            title: "Por firmar",
            value: "SIGNED_PENDING",
            counter: WidgetsData.find((item) => item["status"] === "SIGNED_PENDING") ? WidgetsData.find((item) => item["status"] === "SIGNED_PENDING")["count"] : 0
        },
        {
            color: "quaternary",
            title: "En notaría",
            value: "IN_NOTARY",
            counter: WidgetsData.find((item) => item["status"] === "IN_NOTARY") ? WidgetsData.find((item) => item["status"] === "IN_NOTARY")["count"] : 0
        },
        {
            color: "primary",
            title: "Entregados",
            value: "FINISHED",
            counter: WidgetsData.find((item) => item["status"] === "FINISHED") ? WidgetsData.find((item) => item["status"] === "FINISHED")["count"] : 0
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {

        if (
            !verifiVariable(state) &&
            !verifiVariable(state.list) &&
            tramitsTypeArray.includes(state.list) &&
            !verifiVariable(dataWidgets)
        ) {

            if (state.list === tramitsTypeArray[2]) {
                setWidgetsData(dataWidgets.filter((item) => (item["type"] === "FES" && item["process_type"] === tramitsTypeArray[2])));
            } else if (state.list === tramitsTypeArray[1]) {
                setWidgetsData(dataWidgets.filter((item) => (item["type"] === "FES" && item["process_type"] === tramitsTypeArray[1])));
            } if (state.list === tramitsTypeArray[0]) {
                setWidgetsData(dataWidgets.filter((item) => (item["type"] === "FEA")));
            } else if (state.list === tramitsTypeArray[3]) {
                const totalCreated = dataWidgets.filter((item) => item["status"] === "CREATED").map((item) => item["count"]);
                const totalRejected = dataWidgets.filter((item) => item["status"] === "REJECTED").map((item) => item["count"]);
                const totalReview = dataWidgets.filter((item) => item["status"] === "REVIEW").map((item) => item["count"]);
                const totalPaymentPending = dataWidgets.filter((item) => ["PENDING", "PARTIALLY_PAID"].includes(item["status"])).map((item) => item["count"]);
                const totalSignedPending = dataWidgets.filter((item) => item["status"] === "SIGNED_PENDING").map((item) => item["count"]);
                const totalInotary = dataWidgets.filter((item) => item["status"] === "IN_NOTARY").map((item) => item["count"]);
                const totalFinish = dataWidgets.filter((item) => item["status"] === "FINISHED").map((item) => item["count"]);

                setWidgetsData([
                    {
                        status: "REJECTED",
                        count: totalRejected.reduce(getSum, 0)
                    },
                    {
                        status: "CREATED",
                        count: totalCreated.reduce(getSum, 0)
                    },
                    {
                        status: "REVIEW",
                        count: totalReview.reduce(getSum, 0)
                    },
                    {
                        status: "PENDING",
                        count: totalPaymentPending.reduce(getSum, 0)
                    },
                    {
                        status: "SIGNED_PENDING",
                        count: totalSignedPending.reduce(getSum, 0)
                    },
                    {
                        status: "IN_NOTARY",
                        count: totalInotary.reduce(getSum, 0)
                    },
                    {
                        status: "FINISHED",
                        count: totalFinish.reduce(getSum, 0)
                    }
                ]);

            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dataWidgets]);


    /* useEffect(() => {
        console.log("REJECTED: ", WidgetsData.filter(contract => ["REJECTED"].includes(contract.sStatus)).length);
        console.log("REVIEW: ", WidgetsData.filter(contract => ["REVIEW"].includes(contract.sStatus)).length);
        console.log("CREATED: ", WidgetsData.filter(contract => ["CREATED"].includes(contract.sStatus)).length);
        console.log("SIGNED_PENDING: ", WidgetsData.filter(contract => ["SIGNED_PENDING"].includes(contract.sStatus)).length);
        console.log("DELIVERED: ", WidgetsData.filter(contract => ["DELIVERED"].includes(contract.sStatus)).length);
        console.log("IN_NOTARY: ", WidgetsData.filter(contract => ["IN_NOTARY"].includes(contract.sStatus)).length);
        console.log("PAY PENDING: ", WidgetsData.filter(contract => contract.sStatus === "APPROVED" && ["PENDING"].includes(contract.sPaymentStatus)).length);
        console.log("PAY PARTIALLY_PAID: ", WidgetsData.filter(contract => contract.sStatus === "APPROVED" && ["PARTIALLY_PAID"].includes(contract.sPaymentStatus)).length);
        console.log("FINISHED: ", WidgetsData.filter(contract => ["FINISHED"].includes(contract.sStatus)).length);
    }, [WidgetsData]); */

    useEffect(() => {

        const { token } = user;

        dispatch(getDataWidgets(token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const convertCount = (count) => {
        if (count >= 1000 && count < 1000000) {
            return (count / 1000).toFixed(1) + "K";
        } else if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + "M";
        } else {
            return count;
        }
    }

    return (
        <div className="slider-container cards__container">

            {
                isMobile ?
                    (
                        <Slider className='custom-class-slider' {...settings}>

                            {fields && [...fields].map((field, index) => {
                                return (
                                    <CounterByStatus
                                        key={index}
                                        bgColor={field.color}
                                        count={convertCount(field.counter)}
                                        procedure={field.title}
                                        avatar={field.avatar}
                                        imageAvatar={field.img}
                                        value={field.value}
                                    />
                                )
                            })}

                        </Slider>
                    ) :
                    fields && fields.map((field, index) => {
                        return (
                            <CounterByStatus
                                key={index}
                                bgColor={field.color}
                                count={convertCount(field.counter)}
                                procedure={field.title}
                                avatar={field.avatar}
                                imageAvatar={field.img}
                                value={field.value}
                            />
                        )
                    })
            }

        </div>
    );
}

export default ContractListHeader;
