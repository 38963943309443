import React from 'react'

import { Box, Button, Fade, Grid, styled, Tooltip, Typography } from '@mui/material';

import { TramitDetailDataStyles } from '../index.css'
import { ReactComponent as FileIcon } from "../../../assets/icons/file-icon.svg"
import { ReactComponent as IconEye } from "../../../assets/icons/icon-eye-outlined-blue-light.svg"
import { ReactComponent as IconDownloadWhite } from '../../../assets/icons/icon-download-white-outlined.svg'
import { ReactComponent as IconDocumentWhite } from '../../../assets/icons/icon-document-white-outlined.svg'
import { verifiVariable } from '../../../utils/enums';

const CustomButton = styled(Button)((props) => ({
    padding: '0',
    width: '2.5rem',
    minWidth: '2.5rem',
    height: '2rem',
    textTransform: 'none',

    '& > .text': {
        display: 'none',
        [props.theme.breakpoints.up('sm')]: {
            fontFamily: 'PoppinsBold',
            display: 'inline-block',
            fontSize: '.65rem'
        }
    },

    '& .css-y6rp3m-MuiButton-startIcon': {
        padding: '0',
        margin: '0',
        [props.theme.breakpoints.up('lg')]: {
            margin: '0 .5rem 0 0'
        }
    },

    [props.theme.breakpoints.up('sm')]: {
        width: '12rem',
    },

    '& > span:first-of-type': {
        padding: '0',
        margin: '0',
        [props.theme.breakpoints.up('lg')]: {
            margin: '0 .5rem 0 0'
        }
    },

    [props.theme.breakpoints.up('lg')]: {
        width: '12.5rem',

        height: '2.25rem',
    }

}));

const CustomButtonWhite = styled(CustomButton)((props) => ({
    border: `1px solid ${props.theme.palette.primary.light}`,
    backgroundColor: props.theme.palette.white.main,
    margin: '7px',
    '& > .text': {
        color: props.theme.palette.primary.main
    },
}));

const CustomButtonBlue = styled(CustomButton)((props) => ({
    backgroundColor: props.theme.palette.primary.light,
    border: 'none',
    margin: '7px',
    '& > .text': {
        color: props.theme.palette.white.main
    },
}));

const DocumentInfo = ({
    signLog,
    sectionTitle,
    isReadyToView,
    isReadyToDownload,
    isReplaceable,
    viewDocument,
    downloadDocument,
    downloadLogDocument,
    replaceDocument,
    documentName,

}) => {

    const styles = TramitDetailDataStyles();

    return (
        <Grid
            item
            xs={12}
        >
            <Box className={styles.numberTitle}>
                <Typography
                    className={styles.stepNumbers}
                    variant="h5"
                    gutterBottom
                >
                    3
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    className={styles.detailTextTitle}
                >
                    {sectionTitle
                        ? 'Descargar el documento'
                        : 'Documento a firmar'}
                    <br />
                    <span className={styles.detailTextSecondaryTitle}>Datos del documento</span>
                </Typography>
            </Box>

            <Box className={styles.mainInfo_box}>

                <Box className={styles.blueBox}>

                    {
                        !verifiVariable(documentName) &&
                        (
                            <Box className={styles.documentName_box}>
                                <FileIcon style={{ width: '5rem', margin: { xs: '1rem', md: '0' } }} />
                                <Typography className={styles.documentName}>{documentName}</Typography>
                            </Box>
                        )
                    }

                    {/* TODO punto de quiebre del responsive */}
                    <Box className={styles.document_actionButtons}>

                        {
                            !verifiVariable(documentName) &&
                            isReadyToView &&
                            (
                                <Tooltip
                                    title='Ver documento'
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <CustomButtonWhite
                                        variant="outlined"
                                        startIcon={<IconEye />}
                                        onClick={viewDocument}
                                        size="small"
                                        className={styles.buttonTramit2}
                                    >
                                        <span className='text'>Ver documento</span>
                                    </CustomButtonWhite>
                                </Tooltip>
                            )
                        }

                        {
                            !verifiVariable(documentName) &&
                            isReadyToDownload &&
                            (
                                <Tooltip
                                    title='Descargar documento'
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <CustomButtonBlue
                                        variant="outlined"
                                        startIcon={<IconDownloadWhite />}
                                        onClick={downloadDocument}
                                        size="small"
                                        className={styles.buttonTramit2}
                                    >
                                        <span className='text'>Descargar documento</span>
                                    </CustomButtonBlue>
                                </Tooltip>
                            )
                        }

                        {signLog && (
                            <Tooltip
                                title='Descargar log de firma'
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                            >
                                <CustomButtonBlue
                                    variant="outlined"
                                    startIcon={<IconDownloadWhite />}
                                    onClick={downloadLogDocument}
                                    size="small"
                                    className={styles.buttonTramit2}
                                >
                                    <span className='text'>Log de firma</span>
                                </CustomButtonBlue>
                            </Tooltip>
                        )}

                        {isReplaceable && (
                            <Tooltip
                                title='Reemplazar o editar documento'
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                            >
                                <CustomButtonBlue
                                    variant="outlined"
                                    startIcon={<IconDocumentWhite />}
                                    onClick={replaceDocument}
                                    size="small"
                                    className={styles.buttonTramit2}
                                >
                                    <span className='text'>Reemplazar / Editar</span>
                                </CustomButtonBlue>
                            </Tooltip>
                        )}

                    </Box>

                </Box>

            </Box>

        </Grid>
    )
}

export default DocumentInfo