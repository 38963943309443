import { useEffect, /* useMemo, */ useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import { /* FaSearch,  */FaSearchMinus, FaSearchPlus } from "react-icons/fa";

import { ReactComponent as Logo } from "../../../assets/logo/logo.svg";
import { getUserInfo } from "../../storeSelector/storeSelector";
import { AppBarMenuStyles } from "./AppBarMenu.css";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import WhatsappIconSpeedDial from "../../whatsappIconSpeedDial";
import { to, verifiVariable } from "../../../utils/enums";
import { searchInput } from "../../../store/contract/contactListFilters";
import { verifyUUIDv4 } from "../../../utils/validations";



// eslint-disable-next-line
const notifications = [
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    }
];

const AppBarMenu = ({ appBarHeight }) => {
    const refInputControl = useRef();
    const dispatch = useDispatch();
    const {
        contractListFilters: { loadingSearchInput, searchInputData }
    } = useSelector((state) => state.root.contract);
    const { user } = useSelector((state) => state.root.session.login);
    const styles = AppBarMenuStyles();
    const screenSizeHook = ScreenSizeHook();
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    const [showSearch, setShowSearch] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };


    const handleKeyPressAndBlur = (e) => {
        const verify = e.target.value.split("-").length - 1;

        if (verify === 0) {

            setOpen(true);
            const { token } = user;

            dispatch(searchInput(1, 300, token, { ap_number: e.target.value.toUpperCase().trim().replace(/\s+/g, '') }));
        }

    };

    const handleSearch = (value) => {
        // setSearchValue(value);

        if (!verifiVariable(value)) {

            if (value["modal"] === "SIGNATURE_ANF") {
                navigate(to.ANF_DETAIL, {
                    state: { sContractID: value["sContractID"] },
                });
            } else if (value["sProcess"] === "NORMAL") {
                navigate(to.CONTRACT_DETAIL, { state: value["sContractID"] });
            } else if (value["sProcess"] === "EXPRESS") {
                navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                    state: { sContractID: value["sContractID"] },
                });
            }
        }
    };


    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }

        if (typeof option === "string") return option;

        if (!verifiVariable(option.sTipoContrato)) {
            return `${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma}${option.modal !== "SIGNATURE_ANF" ? " - " + option.sProcess : ""}`;
        } else {
            return `${option.sContractID} - ${option.autoId}`;
        }
    };

    useEffect(() => {
        setLoading(loadingSearchInput);
    }, [loadingSearchInput]);

    useEffect(() => {
        if (!verifiVariable(searchInputData)) {
            const { data } = searchInputData;
            setOptions(data);
        }

    }, [searchInputData]);

    return (
        <Box className={styles.mainContent}>

            <div style={{ height: showSearch ? 42 : appBarHeight }} className={styles.topUserContent}>

                <Logo className="logo-size--xs" />

                <Box className={styles.rightBlock}>
                    {/* !['xs'].includes(screenSizeHook) && */
                        (
                            <Box className={styles.iconsBlock}>

                                {
                                    showSearch ?
                                        (
                                            <FaSearchMinus
                                                style={{ marginRight: 10 }}
                                                className={"search-icon icon--xs icon--grey-dark"}
                                                onClick={() => setShowSearch((value) => !value)}
                                            />
                                        ) :
                                        (
                                            <FaSearchPlus
                                                style={{ marginRight: 10 }}
                                                className={"search-icon icon--xs icon--grey-dark"}
                                                onClick={() => setShowSearch((value) => !value)}
                                            />
                                        )
                                }

                                {/* <PopupStateNotifications notifications={notifications} /> */}

                            </Box>
                        )
                    }

                    <Box className={styles.userInfoContent}>

                        <WhatsappIconSpeedDial screen={screenSizeHook} />

                        {!['xs'].includes(screenSizeHook) && (<Box className={styles.personInfoContent}>
                            <strong className={styles.userName}>{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                            <span className={styles.userRol}>Administrator</span>
                        </Box>)}

                        <Avatar alt="User name" />
                    </Box>

                </Box>

            </div>

            {
                showSearch &&
                (
                    <AutocompleteMui
                        open={open}
                        onClose={handleClose}
                        getLimitTagsText={2}
                        fullWidth
                        autoHighlight
                        multiple={false}
                        freeSolo
                        options={options}
                        value={searchValue}
                        loading={loading}
                        loadingText="Buscando..."
                        isOptionEqualToValue={(option, value) => option.sContractID === value.sContractID}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;

                            if (!verifiVariable(option.sTipoContrato)) {
                                return (
                                    <Typography
                                        key={key}
                                        {...optionProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClose();
                                            setSearchValue(option);
                                            handleSearch(option);
                                        }}
                                    >
                                        {`${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma} ${option.sTipoFirma}${option.modal !== "SIGNATURE_ANF" ? " - " + option.sProcess : ""}`}
                                    </Typography>
                                );
                            } else {
                                return (
                                    <Typography
                                        key={key}
                                        {...optionProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClose();
                                            setSearchValue();
                                            handleSearch(option);
                                        }}
                                    >
                                        {option.sContractID} - {option.autoId}
                                    </Typography>
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                ref={refInputControl}
                                {...params}
                                onChange={(e) => {
                                    if (![0, 1, 2, 3].includes(e.target.value.length)) {
                                        const getSlice = e.target.value.slice(0, 4);
                                        const verify = getSlice.includes("AP") || getSlice.includes("ap") || getSlice.includes("ASN") || getSlice.includes("asn");
                                        if (verify) handleKeyPressAndBlur(e);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {

                                        const contractID = e.target.value.split(" - ")[0];

                                        if ([verifyUUIDv4(contractID)].includes(true)) {

                                            const splitValue = e.target.value.split(" - ");

                                            if (splitValue[splitValue.length - 1] === "EXPRESS") handleSearch({ sProcess: "EXPRESS", sContractID: contractID });

                                            if (splitValue[splitValue.length - 1] === "NORMAL") handleSearch({ sProcess: "NORMAL", sContractID: contractID });

                                            if (splitValue[splitValue.length - 1] === "FIRMA ANF") handleSearch({ modal: "SIGNATURE_ANF", sContractID: contractID });

                                        } else {
                                            handleKeyPressAndBlur(e);
                                        }

                                    }
                                }}
                                label="Buscar trámite por numero de AP"
                                variant="outlined"
                            />
                        )}
                    />
                )
            }

        </Box>

    );
}

export default AppBarMenu;