import { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import PropTypes from "prop-types";
import { verifiVariable } from "../../../../../utils/enums";
import { contractPager } from "../../../../../store/contract/contactListFilters";



const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {
        session: {
            login: { user },
        },
        contract: {
            contractListFilters: { pagerData, filterListData }
        },
    } = useSelector((state) => state.root);

    const disabledBackButtom = useMemo(() => {

        if (!verifiVariable(pagerData)) {

            const { pagination: { currentPage } } = pagerData;

            if (currentPage <= 1) {
                return true;
            } else {
                return false;
            }

        }

        return true;
    }, [pagerData]);

    const disabledNextButtom = useMemo(() => {

        if (!verifiVariable(pagerData)) {

            const { pagination: { currentPage, totalPages } } = pagerData;

            if (currentPage >= totalPages) {
                return true;
            } else {
                return false;
            }

        }

        return true;
    }, [pagerData]);

    const handleFirstPageButtonClick = (event) => {
        // onPageChange(event, 0);
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { perPage } } = pagerData;
            dispatch(contractPager(1, perPage, token, filterListData));
        }
    };

    const handleBackButtonClick = (event) => {
        // onPageChange(event, page - 1);
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { currentPage, perPage } } = pagerData;
            dispatch(contractPager((currentPage - 1), perPage, token, filterListData));
        }
    };

    const handleNextButtonClick = (event) => {
        // onPageChange(event, page + 1);
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { currentPage, perPage } } = pagerData;
            dispatch(contractPager((currentPage + 1), perPage, token, filterListData));
        }
    };

    const handleLastPageButtonClick = (event) => {
        // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            const { pagination: { totalPages, perPage } } = pagerData;
            dispatch(contractPager(totalPages, perPage, token, filterListData));
        }
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                // disabled={page === 0}
                disabled={disabledBackButtom}
                aria-label="primera página"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={disabledBackButtom}
                // disabled={page === 0}
                aria-label="página anterior"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                disabled={disabledNextButtom}
                aria-label="siguiente página"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                disabled={disabledNextButtom}
                aria-label="ultima página"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
